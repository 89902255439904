/* This example requires Tailwind CSS v2.0+ */
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  PuzzleIcon,
  BeakerIcon,
  UsersIcon,
  ThumbUpIcon,
  SparklesIcon,
  GiftIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";
import Emoji from "react-emoji-render";

const features = [
  {
    name: "Marketplace",
    description: "Buy, sell and bid on unicutes.",
    icon: UsersIcon,
  },
  {
    name: "+20 million combinations",
    description:
      "The likelihood of 2 unicutes looking exactly the same is extremely low.",
    icon: BeakerIcon,
  },
  {
    name: "Verifiable RNG",
    description:
      "Every unicute uses a verifiable and provable random number generator (RNG) for creation.",
    icon: PuzzleIcon,
  },
  {
    name: "Fair distribution",
    description:
      "The first 100 unicutes eggs will be sold at 100 ZIL, then 200 at 400 ZIL, 300 at 800 ZIL and the remaining eggs at +999 ZIL.",
    icon: ThumbUpIcon,
  },
  {
    name: "Rare artwork airdrop",
    description: //Airdrops with limited edition unicutes artwork as NFTs for unicutes holders.
      "Unicute owners will be eligible for rare artwork airdrops.",
    icon: GiftIcon,
  }, 
  {
    name: "Exclusively on Zilliqa",
    description: "Unicutes will exclusively exist on the Zilliqa blockchain.",
    icon: SparklesIcon,
  },
];

export default function FeatureSection() {
  const [imagePointer, setImagePointer] = useState(0);
  const images = ["fight.jpg"];
  return (
    <div className="overflow-hidden bg-gray-50">
      <div className="relative max-w-screen mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              No more than 9'999 unicutes will ever exist!
            </h2>
            <div className="block m-12">
              <img
                className="border-solid border-4 align-middle block border-gray-900 shadow-retro pointer-events-none"
                alt="unicutes"
                src={"slideshow/" + images[Math.abs(imagePointer) % images.length]}
              />
            </div>
          </div>
          <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-none border-2 bg-unicutePurple-200 border-gray-900 shadow-retro text-gray-900">
                    <feature.icon className="h-6 w-6 text-gray-900" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-xl leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 text-base font-medium text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
