/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {  XIcon } from "@heroicons/react/outline";
import { ModalStyle } from "../../../../components/modal/ModalStyle";
import UnicuteDetailsContext from "../../../../components/contexts/UnicuteDetailsContext";

function Chop(str, size) {
  if (str === null) return [];
  str = String(str);
  size = ~~size;
  return size > 0 ? str.match(new RegExp(".{1," + size + "}", "g")) : [str];
}

export default function UnicuteRandomValuesModal() {
  const { showRandomValues, unicute, setShowRandomValues } =
    useContext(UnicuteDetailsContext);

  return (
    <Transition.Root show={showRandomValues} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setShowRandomValues}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={ModalStyle()}>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 p-6">
                <button
                  onClick={() => setShowRandomValues(false)}
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-indigo-500"
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Random values
                </h2>
                <p className="mt-2 max-w-2xl text-xs font-medium text-gray-500">
                  The below random values have been fetched from
                  https://drand.love.
                </p>
                <p className="mt-2 max-w-2xl text-xs font-medium text-gray-500">
                  To verify the values send a GET request to one of the
                  following endpoint:
                </p>

                <p className="mt-1 max-w-2xl text-xs font-medium text-gray-500">
                  https://api.drand.sh/public/[insert_round]
                </p>
                <p className="mt- max-w-2xl text-xs font-medium text-gray-500">
                  https://api2.drand.sh/public/[insert_round]
                </p>
                <p className="mt-0 max-w-2xl text-xs font-medium text-gray-500">
                  https://api3.drand.sh/public/[insert_round]
                </p>
                <p className="mt-2 max-w-2xl text-xs font-medium text-gray-500">
                  Or see their documentation{" "}
                  <a href="https://drand.love/developer/http-api/#public-latest">
                    here
                  </a>
                </p>
              </div>
              <div className="grid grid-cols-12 text-center text-gray-600 gap-4 mb-4 px-4 sm:px-6">
                <div className="col-span-12 text-left text-xs font-medium">
                  <div className="grid grid-cols-12">
                    <div className="col-span-8 text-gray-500">First round:</div>
                    <div className="col-span-4 mb-1">
                      {unicute?.drandRound === null ||
                      unicute?.drandRound === undefined
                        ? "in queue for drand round"
                        : unicute.drandRound}
                    </div>
                    <div className="col-span-8 text-gray-500">Second round</div>
                    <div className="col-span-4 mb-1">
                      {unicute?.drandRound === null ||
                      unicute?.drandRound === undefined
                        ? "in queue for drand round"
                        : +parseInt(unicute.drandRound) + +1}
                    </div>
                  </div>
                </div>
                <div className="col-span-12"></div>
              </div>
              <div className="px-4 py-1 sm:px-6 inline-block">
                <p className="text-sm leading-6 font-medium text-gray-900 mb-2">
                  Values from drand
                </p>
                <div className="grid grid-cols-1 grid-rows-12 gap-4 text-xs font-medium text-gray-900">
                  <div>
                    {Chop(unicute?.drandValue, 2).map((dValue, index) => {
                      return (
                        <div key={index.toString() + "-drand-values"} className="w-8 inline-block shadow-retro mr-2 mb-4 bg-gray-100 border-2 border-gray-900 p-1 text-center">
                          {dValue}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
