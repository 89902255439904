export default function TermsAndConditions() {
  return (
    <div className="relative py-16 overflow-hidden text-left">
      <div className="relative">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-center text-gray-900 font-medium text-2xl tracking-wide uppercase">
              Unicutes
            </span>
            <span className="block text-center text-gray-900 font-medium text-xs tracking-wide">
              Owned and operated by{" "}
              <a className="text-gray-900 font-medium" href="https://texplo.ch">
                Technology Exploration Labs AG
              </a>
              .
            </span>
            <span className="block text-center text-gray-900 font-medium text-xs tracking-wide">
              <a href="https://texplo.ch">https://texplo.ch</a>
            </span>
            <span className="mt-4 block text-center leading-8 font-medium tracking-tight text-gray-900 text-xl">
              Privacy and data protection
            </span>
          </h1>
          <p className="mt-8 text-base text-gray-500 leading-8">
            Unicutes does not store or collect any personally identifiable
            information. Unicutes does not create any session or any other type
            of cookies.
          </p>

          <h1>
            <span className="mt-4 block text-center leading-8 font-medium tracking-tight text-gray-900 text-xl">
              Terms and conditions
            </span>
          </h1>
          <p className="mt-8 text-base text-gray-500 leading-8">
            Unicutes is a collection of digital artworks (NFTs) running on the
            Zilliqa network. This website is only an interface allowing
            participants to exchange digital collectibles. Users are entirely
            responsible for the safety and management of their own private
            Zilliqa wallets and validating all transactions and contracts
            generated by this website before approval. Furthermore, as the
            Unicutes smart contracts runs on the Zilliqa network, there is no
            ability to undo, reverse, or restore any transactions.
          </p>
          <p className="mt-8 text-base text-gray-500 leading-8">
            This website and its connected services are provided “as is” and “as
            available” without warranty of any kind. By using this website you
            are accepting sole responsibility for any and all transactions
            involving Unicutes digital collectibles.
          </p>
          <h1>
            <span className="mt-8 block text-center leading-8 font-medium tracking-tight text-gray-900 text-xl">
              Ownership
            </span>
          </h1>
          <p className="mt-8 text-base text-gray-500 leading-8">
            i. You Own the NFT. Each Unicute is an NFT on the Zilliqa
            blockchain. When you purchase an NFT, you own the underlying
            Unicute, the Art, completely. Ownership of the NFT is mediated
            entirely by the Smart Contract and the Zilliqa Network: at no point
            may we seize, freeze, or otherwise modify the ownership of any
            Unicute.
          </p>
          <p className="mt-8 text-base text-gray-500 leading-8">
            ii. Personal Use. Subject to your continued compliance with these
            Terms, Unicutes grants you a worldwide, royalty-free license to use,
            copy, and display the purchased Art, along with any extensions that
            you choose to create or use, solely for the following purposes: (i)
            for your own personal, non-commercial use; (ii) as part of a
            marketplace that permits the purchase and sale of your Unicute /
            NFT, provided that the marketplace cryptographically verifies each
            Unicute owner’s rights to display the Art for their Unicute to
            ensure that only the actual owner can display the Art; or (iii) as
            part of a third party website or application that permits the
            inclusion, involvement, or participation of your Unicute, provided
            that the website/application cryptographically verifies each Unicute
            owner’s rights to display the Art for their Unicute to ensure that
            only the actual owner can display the Art, and provided that the Art
            is no longer visible once the owner of the Unicute leaves the
            website/application.
          </p>
          <p className="mt-8 text-base text-gray-500 leading-8">
            iii. Commercial Use. Subject to your continued compliance with these
            Terms, Unicutes grants you an unlimited, worldwide license to use,
            copy, and display the purchased Art for the purpose of creating
            derivative works based upon the Art (“Commercial Use”). Examples of
            such Commercial Use would e.g. be the use of the Art to produce and
            sell merchandise products (T-Shirts etc.) displaying copies of the
            Art. For the sake of clarity, nothing in this Section will be deemed
            to restrict you from (i) owning or operating a marketplace that
            permits the use and sale of Unicutes generally, provided that the
            marketplace cryptographically verifies each Unicute owner’s rights
            to display the Art for their Unicute to ensure that only the actual
            owner can display the Art; (ii) owning or operating a third party
            website or application that permits the inclusion, involvement, or
            participation of Unicutes generally, provided that the third party
            website or application cryptographically verifies each Unicute
            owner’s rights to display the Art for their Unicute to ensure that
            only the actual owner can display the Art, and provided that the Art
            is no longer visible once the owner of the Purchased Unicute leaves
            the website/application; or (iii) earning revenue from any of the
            foregoing.
          </p>
        </div>
      </div>
    </div>
  );
}
