import { useState } from "react";
import { useParams } from "react-router";
import UnicutesPropertiesContext from "../../components/contexts/UnicutesPropertiesContext";
import SimpleImagePopup from "../../components/popup/SimpleImagePopup";
import UnicutePartStatistics from "../../components/statistics/UnicutePartStatistics";
import Tab from "../../components/tab/Tab";

const tabs = [
  { name: "Tail", href: "/traits/tail" },
  { name: "Hooves", href: "/traits/hooves" },
  { name: "Eyes", href: "/traits/eyes" },
  { name: "Ears", href: "/traits/ears" },
  { name: "Pattern", href: "/traits/pattern" },
  { name: "Hair", href: "/traits/hair" },
  { name: "Horn", href: "/traits/horn" },
  { name: "Body", href: "/traits/body" },
  { name: "Nose", href: "/traits/nose" },
];

export default function UnicuteProperties() {
  const { trait = "tail" } = useParams();
  const [tabName, setTabName] = useState(tabs[0].name);
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const unicutesPropertiesContext = {
    tabName,
    setTabName,
    showModal,
    setShowModal,
    imageUrl,
    setImageUrl,
  };
  return (
    <div>
      <UnicutesPropertiesContext.Provider value={unicutesPropertiesContext}>
        <SimpleImagePopup imageUrl={imageUrl} showModal={showModal} setShowModal={setShowModal}/>
        <div className="mt-4">
          <Tab items={tabs} currentTab={trait} setTabName={setTabName} />
        </div>
        <UnicutePartStatistics name={tabName.toLowerCase()} />
      </UnicutesPropertiesContext.Provider>
    </div>
  );
}
