export const PrimaryRetroButtonDesign = () => {
  return "border-2 border-gray-900 rounded-none shadow-retro hover:shadow-retroHover transform hover:translate-y-0.5 hover:translate-x-0.5";
};

export const PrimaryButtonStyle = (additionals) => {
  return (
    additionals +
    " transition relative inline-flex items-center px-4 py-2 bg-unicuteWarmPurple-100 text-gray-900 font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 " +
    PrimaryRetroButtonDesign()
  );
};

export const SecondaryButtonStyle = (additionals) => {
  return (
    additionals +
    " transition relative inline-flex items-center px-4 py-2 bg-unicuteYellow-400 text-gray-900 font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 " +
    PrimaryRetroButtonDesign()
  );
};

export const InactivePrimaryButtonStyle = (additionals) => {
  return PrimaryButtonStyle(additionals) + " opacity-50 cursor-not-allowed";
};

export const InactiveSecondaryButtonStyle = (additionals) => {
  return SecondaryButtonStyle(additionals) + " opacity-50 cursor-not-allowed";
};
