/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ModalStyle } from "../../modal/ModalStyle";
import WalletContext from "../../contexts/WalletContext";
import {
  InactivePrimaryButtonStyle,
  PrimaryButtonStyle,
} from "../../button/ButtonStyle";
import {
  GetSmartContractSubStateWithoutAddr,
  QaToZil,
  UnicutesContract,
  UnicutesMarketplaceContract,
} from "../../../utils/ZilliqaUtils";
import {
  InteractWithContractAcceptBid,
  InteractWithContractSetApprove,
} from "../../../utils/ZilPayUtils";
import {
  InformationNotification,
  SuccessNotification,
} from "../../notification/Notification";
import GetTokenApprovals from "../../../data/smartcontract/unicutes/GetTokenApprovals";
import { AcceptBidFragment, ApproveTransferFragment } from "./BidModalActions";

export default function BidModal(props) {
  const [isApproverRetrieved, setIsApproverRetrieved] = useState(false);
  const [isTransferApproved, setIsTransferApproved] = useState(false);
  const [isApprovingConfirming, setIsApprovingConfirming] = useState(false);
  const [isBidAcceeptConfirming, setIsBidAcceptConfirming] = useState(false);
  const { setTriggerBids, setTriggerOwnedUnicutes } = useContext(WalletContext);
  function AcceptBidCallback() {
    setIsBidAcceptConfirming(true);
  }
  function ApproveTransferCallback() {
    setIsApprovingConfirming(true);
  }

  function IsTokenReadyForTransfer(isCancelled, acceptedBidData) {
    !isCancelled &&
      GetTokenApprovals(acceptedBidData.tokenId).then((spender) => {
        if (isTransferApproved && spender === null) {
          !isCancelled &&
            SuccessNotification(
              "Bid accepted",
              "You have successfully accepted the bid."
            );
          !isCancelled && setTriggerBids(Math.random());
          !isCancelled && setTriggerOwnedUnicutes(Math.random());
          !isCancelled && props.setShowBidModal(false);
        }

        if (spender === null || isTransferApproved) {
          return;
        }

        if (spender === UnicutesMarketplaceContract().toLowerCase()) {
          !isCancelled && setIsApprovingConfirming(false);
          !isCancelled && setIsTransferApproved(true);
          !isCancelled &&
            InformationNotification(
              props.tokenName + " transfer approved",
              "Bid on " +
                props.tokenName +
                " #" +
                acceptedBidData.tokenId.toString() +
                " can now be accepted."
            );
        }
        !isCancelled && setIsApproverRetrieved(true);
      });
  }

  useEffect(() => {
    let isCancelled = false;
    // start reset
    !isCancelled && setIsApprovingConfirming(false);
    !isCancelled && setIsApproverRetrieved(false);
    // end reset
    if (
      props.acceptedBidData === null ||
      props.acceptedBidData === undefined ||
      !props.showBidModal
    ) {
      return;
    }

    !isCancelled && IsTokenReadyForTransfer(isCancelled, props.acceptedBidData);

    return () => {
      isCancelled = true;
    };
  }, [props.acceptedBidData, props.showBidModal]);

  useEffect(() => {
    let isCancelled = false;
    if (!isBidAcceeptConfirming && !isApprovingConfirming) {
      return;
    }
    if (!props.showBidModal) {
      return;
    }
    const interval = setInterval(() => {
      !isCancelled &&
        IsTokenReadyForTransfer(isCancelled, props.acceptedBidData);
    }, 10000);

    return () => {
      isCancelled = true;

      clearInterval(interval);
    };
  }, [isBidAcceeptConfirming, isApprovingConfirming, props.showBidModal]);

  return (
    <Transition.Root show={props.showBidModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={props.setShowBidModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={ModalStyle()}>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 p-6">
                <button
                  onClick={() => props.setShowBidModal(false)}
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-indigo-500"
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {props?.acceptedBidData?.amount === null ? (
                ""
              ) : (
                <div className="px-4 py-5 sm:px-6">
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Accept bid -{" "}
                    {QaToZil(props?.acceptedBidData?.amount ?? 0) + " ZIL"}
                  </h2>
                  {props.acceptedBidData !== null ? (
                    <>
                      <p className="mt-2 max-w-2xl text-xs font-medium text-gray-500">
                        First, approve the transfer of the{" "}
                        {props.tokenName.toLowerCase()}, and after that, you can
                        accept the bid.
                      </p>
                      <div className="col-span-6 pt-4">
                        <div className="pb-2">
                          <ApproveTransferFragment
                            interactWithContractToApproveTransfer={
                              InteractWithContractSetApprove
                            }
                            successCallback={ApproveTransferCallback}
                            tokenId={props.acceptedBidData.tokenId}
                            tokenName={props.tokenName}
                            isTransferApproved={isTransferApproved}
                            isApprovingConfirming={isApprovingConfirming}
                          />
                        </div>
                        <AcceptBidFragment
                          interactWithContractToAcceptBid={
                            InteractWithContractAcceptBid
                          }
                          successCallback={AcceptBidCallback}
                          tokenId={props.acceptedBidData.tokenId}
                          tokenName={props.tokenName}
                          address={props.acceptedBidData.address}
                          amount={props.acceptedBidData.amount}
                          isTransferApproved={isTransferApproved}
                          isBidAcceptConfirming={isBidAcceeptConfirming}
                        />
                      </div>
                    </>
                  ) : (
                    <p className="mt-2 max-w-2xl text-xs font-medium text-gray-500">
                      Loading, please wait...
                    </p>
                  )}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
