/* This example requires Tailwind CSS v2.0+ */
import WalletContext from '../contexts/WalletContext'
import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
export default function Banner() {
  const {totalPurchasedEggs, totalGhostUnicutes} = useContext(WalletContext)
  return (
    <NavLink exact to="/">
    <div className="sticky top-0 bg-unicuteGreen-200 mt-0.25 z-50">
      <div className="max-w-7xl mx-auto py-2 px-3 sm:px-6 lg:px-8">
        <div className="sm:text-center sm:px-16">
          <p className="font-medium text-gray-700 text-xs">
            <span className="">{totalPurchasedEggs ?? "NaN"}/9999 eggs and {totalGhostUnicutes}/999 ghost unicutes have been claimed</span>
          </p>
        </div>
      </div>
    </div>
    </NavLink>
  )
}