import { SuccessNotification } from "../components/notification/Notification";

const { Zilliqa } = require("@zilliqa-js/zilliqa");
const { BN, units } = require("@zilliqa-js/util");
const {
  StatusType,
  MessageType,
} = require("@zilliqa-js/subscriptions");

export const Network = () => {
  return "mainnet";
};
export const ViewBlockUrl = (contract, network) => {
  return (
    "https://viewblock.io/zilliqa/address/" +
    contract +
    "?network=" +
    network +
    "&tab=state"
  );
};

const ZilliqaWsUrl = () => {
  return "wss://api-ws.zilliqa.com";
}

const ZilliqaUrl = () => {
  return "https://api.zilliqa.com";
};

export const CdnImageLocation = () => {
  return "https://images.unicutes.app/09-12-2021-05-52-31";
};
export const CdnGhostUnicutesImageLocation = () => {
  return "https://images.unicutes.app/ghost-unicutes/";
}
export const CdnWorldOfUnicutesImageLocation = () => {
  return "https://images.unicutes.app/world-of-unicutes/";
}

export const UnicutesContract = () => {
  return "0xEA4757C8Ba3C8063878C35D0B0eB05C7bCe892a1";
};

export const WorldOfUnicutesContract = () => {
  return "0x5650D8d638974E1acBB69f70D91d1FCa4Acb2116";
}

export const EggContract = () => {
  return "0xba496B3c744D3B5dba36a2B27E0131a3bfe926B4";
};

export const UnicutesMarketplaceContract = () => {
  return "0x8AB38b1f39F5323CEa1105d8C31de8EE80c1a1ca";
};

export const ProxyContract = () => {
  return "0x2C9c3BFA7082dF3Ee5faEB45F15Bd94C8fac3Df2";
};

export const VotingContestOneContract = () => {
  return "0x2A98EAbbdD7E79F4452CDcC0A8427806d981f908";
}

export const GhostUnicutesContract = () => {
  return "0xC23EBEDEb2F1F2f493C59f50E345A810Ad9baDa9";
}

export const ProxyGhostUnicutesContract = () => {
  return "0x736E9182c141292C95BDDF7624c4a93eACD7AAb0";
}

export const ZilliqaBlockProcessingTimeInSeconds = () => {
  return 35;
};

export function getBlocksLeft(hatchableAt, zilBlockNumber) {
  if (hatchableAt === null) return -1;
  if (zilBlockNumber === null) return -1;
  var blocksLeft = parseInt(hatchableAt) - parseInt(zilBlockNumber);
  if (blocksLeft < 0) {
    return 0;
  } else {
    return blocksLeft;
  }
}

export function newBlockSubscription(setBlockNumberFunc) {
  const zilliqa = new Zilliqa(ZilliqaUrl());

  const subscriber = zilliqa.subscriptionBuilder.buildNewBlockSubscriptions(
    ZilliqaWsUrl()
  );

  // if subscribe success, it will echo the subscription info
  subscriber.emitter.on(StatusType.SUBSCRIBE_NEW_BLOCK, (event) => {
    console.log("get SubscribeNewBlock echo: ", event);
  });

  subscriber.emitter.on(MessageType.NEW_BLOCK, (event) => {
    var val = event?.value?.TxBlock?.header?.["BlockNum"] ?? null;
    if (val !== null) {
      setBlockNumberFunc(val);
    }
  });

  //if unsubscribe success, it will echo the unsubscription info
  subscriber.emitter.on(MessageType.UNSUBSCRIBE, (event) => {
    console.log("get unsubscribe event: ", event);
  });

  subscriber.start().then(() => {
    console.log("subscription started for new blocks!");
  });
}

// Code that listens to websocket and updates welcome message when getHello() gets called.
export function eventLogUnicuteSubscription(
  contract,
  account,
  setTriggerOwnedUnicutes
) {
  const zilliqa = new Zilliqa(ZilliqaUrl());
  const subscriber = zilliqa.subscriptionBuilder.buildEventLogSubscriptions(
    ZilliqaWsUrl(),
    {
      // smart contract address you want to listen on
      addresses: [contract],
    }
  );
  subscriber.emitter.on(StatusType.SUBSCRIBE_EVENT_LOG, (event) => {
    // if subscribe success, it will echo the subscription info
    console.log("get SubscribeEventLog echo : ", event);
  });

  subscriber.emitter.on(MessageType.EVENT_LOG, (event) => {
    let address = account?.current?.base16?.toLowerCase();
    if (
      event.hasOwnProperty("value") &&
      address !== null &&
      address !== undefined
    ) {
      if (event.value[0].event_logs[0]._eventname === "CreateUnicute") {
        console.log(event);
        console.log(event.value[0].event_logs[0].params[2]);
        var sender = event.value[0].event_logs[0].params[2].value;
        if (sender.toString().trim() === address.toString().trim()) {
          var unicute_id = event.value[0].event_logs[0].params[1].value;
          SuccessNotification(
            "Egg hatch success",
            "You have successfully hatched an egg #" + unicute_id,
            "See all unicutes",
            "/my-unicutes/1"
          );
          setTriggerOwnedUnicutes(Math.random());
        }
      }
    }
  });

  subscriber.start().then(() => {
    console.log("subscription started");
  });
}

export function eventLogGeneralSubscription(
  contract,
  eventName,
  setTrigger
) {
  const zilliqa = new Zilliqa(ZilliqaUrl());
  const subscriber = zilliqa.subscriptionBuilder.buildEventLogSubscriptions(
    ZilliqaWsUrl(),
    {
      // smart contract address you want to listen on
      addresses: [contract],
    }
  );
  subscriber.emitter.on(StatusType.SUBSCRIBE_EVENT_LOG, (event) => {
    // if subscribe success, it will echo the subscription info
    console.log("get SubscribeEventLog echo : ", event);
  });

  subscriber.emitter.on(MessageType.EVENT_LOG, (event) => {
    if (
      event.hasOwnProperty("value")
    ) {
      if (event.value[0].event_logs[0]._eventname === eventName) {
        setTrigger(Math.random());
      }
    }
  });

  subscriber.start().then(() => {
    console.log("subscription started");
  });
}

export function eventLogGeneralSubscriptionWithFunc(
  contract,
  execFunc
) {
  const zilliqa = new Zilliqa(ZilliqaUrl());
  const subscriber = zilliqa.subscriptionBuilder.buildEventLogSubscriptions(
    ZilliqaWsUrl(),
    {
      // smart contract address you want to listen on
      addresses: [contract],
    }
  );
  subscriber.emitter.on(StatusType.SUBSCRIBE_EVENT_LOG, (event) => {
    // if subscribe success, it will echo the subscription info
    console.log("get SubscribeEventLog echo : ", event);
  });

  subscriber.emitter.on(MessageType.EVENT_LOG, (event) => {
    if (
      event.hasOwnProperty("value")
    ) {
      execFunc(event);
    }
  });

  subscriber.start().then(() => {
    console.log("subscription started");
  });
}

// Code that listens to websocket and updates welcome message when getHello() gets called.
export function eventLogSubscription(contract, account, setTriggerOwnedEggs) {
  const zilliqa = new Zilliqa(ZilliqaUrl());
  const subscriber = zilliqa.subscriptionBuilder.buildEventLogSubscriptions(
    ZilliqaWsUrl(),
    {
      // smart contract address you want to listen on
      addresses: [contract],
    }
  );
  subscriber.emitter.on(StatusType.SUBSCRIBE_EVENT_LOG, (event) => {
    // if subscribe success, it will echo the subscription info
    console.log("get SubscribeEventLog echo : ", event);
  });

  subscriber.emitter.on(MessageType.EVENT_LOG, (event) => {
    let address = account?.current?.base16?.toLowerCase();
    if (
      event.hasOwnProperty("value") &&
      address !== null &&
      address !== undefined
    ) {
      if (event.value[0].event_logs[0]._eventname === "BuyEgg") {
        var sender = event.value[0].event_logs[0].params[4].value;
        if (sender.toString().trim() === address.toString().trim()) {
          var egg_id = event.value[0].event_logs[0].params[1].value;
          SuccessNotification(
            "Egg purchase success",
            "You have successfully bought egg #" + egg_id,
            "See all eggs",
            "/my-unicutes/0"
          );
          setTriggerOwnedEggs(Math.random());
        }
      }
    }
  });

  subscriber.start().then(() => {
    console.log("subscription started");
  });
}

export function GetSmartContractInit(contract) {
  return new Promise((resolve, reject) => {
    const zilliqa = new Zilliqa(ZilliqaUrl());
    zilliqa.blockchain.getSmartContractInit(contract).then((res) => {
      resolve(res.result);
    });
  });
}

export function getBlockNumber() {
  return new Promise((resolve, reject) => {
    const zilliqa = new Zilliqa(ZilliqaUrl());
    zilliqa.blockchain.getNumTxBlocks().then((numTxBlock) => {
      resolve(numTxBlock.result);
    });
  });
}

export function GetSmartcontractState(contract) {
  return new Promise((resolve, reject) => {
    const zilliqa = new Zilliqa(ZilliqaUrl());
    zilliqa.blockchain
      .getSmartContractState(contract)
      .then((smartContractStateRes) => {
        resolve(smartContractStateRes.result);
      });
  });
}

export function GetSmartContractSubStateWithoutAddr(contract, variableName, indices = null) {
  return new Promise((resolve, reject) => {
    const zilliqa = new Zilliqa(ZilliqaUrl());
    var indicesArg = [];
    if(indices !== null)
    {
      indicesArg = indices;
    }
    zilliqa.blockchain
      .getSmartContractSubState(contract, variableName, indicesArg)
      .then((subState) => {
        console.log(subState);
        resolve(subState?.result?.[variableName] ?? null);
      });
  });
}


export function GetSmartContractSubState(contract, variableName, account) {
  return new Promise((resolve, reject) => {
    const zilliqa = new Zilliqa(ZilliqaUrl());
    var addr = account.base16.toLowerCase();
    zilliqa.blockchain
      .getSmartContractSubState(contract, variableName, [addr])
      .then((subState) => {
        console.log(subState);
        resolve(subState?.result?.[variableName]?.[addr] ?? null);
      });
  });
}

export function QaToZil(balance) {
  return units.fromQa(new BN(balance.toString()), units.Units.Zil);
}

export function GetBalance(account) {
  return new Promise((resolve, reject) => {
    if (account?.bech32 === null || account?.bech32 === undefined) return;
    const zilliqa = new Zilliqa(ZilliqaUrl());
    zilliqa.blockchain.getBalance(account.bech32).then((balanceRes) => {
      console.log(balanceRes);
      var newBalance = balanceRes?.result?.balance ?? 0;
      if (newBalance !== -1) {
        newBalance = QaToZil(newBalance);
      }
      resolve(newBalance);
    });
  });
}

export function GetBech32Account(account) {
  if (account === null) return "";
  var accountAddress = account.bech32;
  var address =
    accountAddress.substring(0, 10) +
    "..." +
    accountAddress.slice(accountAddress.length - 10);
  return address;
}

export const IsConnected = () => {
  try {
    window.zilPay.wallet
      .isConnect()
      .then((result) => {
        console.log("isconnect: " + result);
        return result;
      })
      .catch((e) => {
        console.log("exception: " + e);
        return false;
      });
  } catch (error) {
    return false;
  }
};
