/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ModalStyle } from "../../../../components/modal/ModalStyle";
import UnicuteDetailsContext from "../../../../components/contexts/UnicuteDetailsContext";
import { InteractWithContractPlaceBid } from "../../../../utils/ZilPayUtils";
import WaitingAnimation from "../../../../components/animations/WaitingAnimation";
import {
  GetSmartContractSubState,
  GetSmartContractSubStateWithoutAddr,
  UnicutesMarketplaceContract,
} from "../../../../utils/ZilliqaUtils";
import WalletContext from "../../../../components/contexts/WalletContext";
import { GetBidderTokens, IsBidPlaced } from "../../../../data/smartcontract/unicutesMarket/GetBidderTokens";
import {
  InformationNotification,
  SuccessNotification,
} from "../../../../components/notification/Notification";

export default function PlaceBidModal(props) {
  const [isPlaced, setIsPlaced] = useState(false);
  const [isBeingConfirmed, setIsBeingConfirmed] = useState(false);
  const [bidAmount, setBidAmount] = useState(0);
  const { showPlaceBidModal, setShowPlaceBidModal } = useContext(
    UnicuteDetailsContext
  );
  const { account, setTriggerBids } = useContext(WalletContext);

  function placeBidSuccessCallback() {
    setIsBeingConfirmed(true);
  }

  useEffect(() => {
    let isCancelled = false;
    if (!showPlaceBidModal) {
      return;
    }
    IsBidPlaced(account, props.unicuteId).then((isPlaced) => {
      setIsPlaced(isPlaced);
    });
    return () => {
      isCancelled = false;
    };
  }, [showPlaceBidModal]);

  useEffect(() => {
    let isCancelled = false;
    if (!isBeingConfirmed) {
      return;
    }
    const interval = setInterval(() => {
      if (!showPlaceBidModal || isCancelled || !isBeingConfirmed) {
        return;
      }
      !isCancelled &&
        IsBidPlaced(account, props.unicuteId).then((isPlaced) => {
          if (!isPlaced) {
            return;
          }
          !isCancelled &&
            SuccessNotification(
              "Bid placed",
              "Transaction to bid on unicute #" +
                props.unicuteId.toString() +
                " has been confirmed by the blockchain."
            );
          !isCancelled && setTriggerBids(Math.random());
          !isCancelled && setShowPlaceBidModal(false);
          !isCancelled && setIsBeingConfirmed(false);
        });
    }, 5000);

    return () => {
      isCancelled = true;
      clearInterval(interval);
    };
  }, [isBeingConfirmed]);

  return (
    <Transition.Root show={showPlaceBidModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setShowPlaceBidModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={ModalStyle()}>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 p-6">
                <button
                  onClick={() => setShowPlaceBidModal(false)}
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-indigo-500"
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Place bid
                </h2>
                <div className="col-span-6 pt-4">
                  <div>
                    {isPlaced ? (
                      <p className="mt-2 max-w-2xl text-xs font-medium text-gray-500">
                        Please withdraw your existing bid before placing a new.
                      </p>
                    ) : (
                      <div className="mt-1 flex rounded-none shadow-retro">
                        {isBeingConfirmed ? (
                          <>
                            <input
                              name="company-website"
                              id="company-website"
                              className="opacity-50 cursor-not-allowed pointer-events-none select-none text-gray-900 flex-1 min-w-0 font-medium block w-full px-3 py-2 rounded-none focus:ring-0 focus:ring-indigo-500 focus:border-unicuteGreen-500 sm:text-sm border-2 border-gray-900"
                              placeholder="Amount in ZIL"
                            />
                            <button className="opacity-50 cursor-not-allowed bg-unicuteWarmPurple-100 inline-flex items-center px-3 rounded-none border-2 border-l-0 border-gray-900 text-gray-900 text-sm font-medium">
                              <WaitingAnimation />
                              Placing bid
                            </button>
                          </>
                        ) : (
                          <>
                            <input
                              onChange={(e) => {
                                const re = /^[0-9\b]+$/;
                                // if value is not blank, then test the regex
                                if (e.target.value === "") {
                                  setBidAmount(0);
                                }
                                if (re.test(e.target.value)) {
                                  setBidAmount(e.target.value);
                                }
                              }}
                              type="text"
                              name="company-website"
                              id="company-website"
                              className="text-gray-900 flex-1 min-w-0 font-medium block w-full px-3 py-2 rounded-none focus:ring-0 focus:ring-indigo-500 focus:border-unicuteGreen-500 sm:text-sm border-2 border-gray-900"
                              placeholder="Amount in ZIL"
                            />
                            <button
                              onClick={() =>
                                InteractWithContractPlaceBid(
                                  props.unicuteId,
                                  props.tokenOwner,
                                  bidAmount,
                                  placeBidSuccessCallback
                                )
                              }
                              className="bg-unicuteWarmPurple-100 inline-flex items-center px-3 rounded-none border-2 border-l-0 border-gray-900 text-gray-900 text-sm font-medium"
                            >
                              Place bid
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
