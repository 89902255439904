import MyUnicutesContext from "../../../components/contexts/MyUnicutesContext";
import Timer from "../../../components/timer/Timer";
import { useContext } from "react";
import { SecondsToDateTime } from "../../../utils/DateUtils";
import {
  getBlocksLeft,
  ZilliqaBlockProcessingTimeInSeconds,
} from "../../../utils/ZilliqaUtils.js";
import WalletContext from "../../../components/contexts/WalletContext";
import { InformationNotification } from "../../../components/notification/Notification";

export default function EggsSection(props) {
  const { setModalEggDetailsOpen, setModalEggDetailsData, lockedEggsRef } =
    useContext(MyUnicutesContext);
  const { blockNumber } = useContext(WalletContext);
  console.log(props.eggs);
  return (
    <div>
      <p className="text-sm font-medium text-gray-700 bg-yellow-100 p-2 mt-4">
        Any pending egg purchase transaction will be visible once it has been
        confirmed by the blockchain.
      </p>
      <div className="relative grid grid-cols-12 gap-4 mt-4 mb-8">
        <div className="col-span-12 gap-4">
          <ul className="grid grid-cols-2 gap-x-2 gap-y-8 sm:grid-cols-3 sm:gap-x-2 lg:grid-cols-6 xl:gap-x-4">
            {props.eggs !== null &&
              props.eggs
                .filter((x) => x.state === "1")
                .map((egg) => {
                  return (
                    <li
                      key={egg.id}
                      onClick={() => {
                        if (!lockedEggsRef.current.includes(egg.id)) {
                          setModalEggDetailsData(egg);
                          setModalEggDetailsOpen(true);
                        } else {
                          InformationNotification(
                            "Hatching",
                            "Egg #" + egg.id + " is hatching. Please wait."
                          );
                        }
                      }}
                      className="relative cursor-pointer"
                    >
                      <div
                        className={
                          "border-gray-900 shadow-common hover:shadow-retro pb-4 group border-2 block w-full aspect-w-10 aspect-h-7 rounded-none bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
                        }
                      >
                        <img
                          src="/Zegg.png"
                          alt=""
                          className="object-contain inline h-32 pb-4 pointer-events-none group-hover:opacity-75"
                        />
                        <button
                          type="button"
                          className="absolute inset-0 focus:outline-none"
                        >
                          <span className="sr-only">View details for </span>
                        </button>
                        <div className="absolute inset-x-0 bottom-0 p-2">
                          <div className="text-center text-gray-900 font-medium text-sm">
                            {lockedEggsRef.current.includes(egg.id) ? (
                              "Egg #" + egg.id + " is hatching"
                            ) : egg.state === "1" ? (
                              <Timer
                                id={egg.id}
                                time={SecondsToDateTime(
                                  getBlocksLeft(egg.hatchableAt, blockNumber) *
                                    ZilliqaBlockProcessingTimeInSeconds()
                                )}
                                countdownEndedMessage={
                                  "Hatch #" + egg.id + " now!"
                                }
                              />
                            ) : (
                              "#" + egg.id + " Hatched"
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
          </ul>
        </div>
      </div>
    </div>
  );
}
