/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

function ButtonStyle() {
  return "bg-unicutePurple-100 cursor-pointer bg-opacity-50 border-gray-900 text-gray-900 hover:bg-unicutePurple-200 relative inline-flex items-center px-4 py-2 border-t-2 border-l-2 border-b-2 text-sm font-medium";
}

function ActiveButtonStyle() {
  return "bg-unicutePurple-400 cursor-pointer border-gray-900 text-gray-900 relative inline-flex items-center px-4 py-2 border-t-2 border-l-2 border-b-2 text-sm font-medium";
}

function NextButtonStyle() {
  return "relative inline-flex items-center px-2 py-2 rounded-none border-2 border-gray-900 bg-unicutePurple-100 bg-opacity-50 text-sm font-medium text-gray-900 hover:bg-unicutePurple-200";
}

function NextButtonInactiveStyle() {
  return "relative inline-flex items-center px-2 py-2 rounded-none border-2 border-gray-900 bg-gray-400 text-sm font-medium text-gray-900 cursor-not-allowed";
}

function PreButtonStyle() {
  return "relative inline-flex items-center px-2 py-2 rounded-none border-l-2 border-t-2 border-b-2 border-gray-900 bg-unicutePurple-100 bg-opacity-50 text-sm font-medium text-gray-900 hover:bg-unicutePurple-200";
}

function PreButtonInactiveStyle() {
  return "relative inline-flex items-center px-2 py-2 rounded-none border-l-2 border-t-2 border-b-2 border-gray-900 bg-gray-400 text-sm font-medium text-gray-900 cursor-not-allowed";
}

function GetButtonNumbers(currentPage, maxPage, isRightDirection) {
  if (maxPage <= 5) {
    let retButtonNumbers = [];
    for (let i = 1; i <= maxPage; i++) {
      retButtonNumbers.push(i);
    }
    return retButtonNumbers;
  }
  if (
    parseInt(currentPage) === 1 ||
    parseInt(currentPage) - 1 === 1 ||
    parseInt(currentPage) - 2 === 1
  ) {
    return [1, 2, 3, 4, 0, maxPage];
  }

  if (
    parseInt(currentPage) === parseInt(maxPage) ||
    parseInt(currentPage) + 1 === parseInt(maxPage) ||
    parseInt(currentPage) + 2 === parseInt(maxPage)
  ) {
    return [1, 0, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
  }

  if (isRightDirection) {
    return [1, 0, currentPage - 1, currentPage, currentPage + 1, 0, maxPage];
  }

  if (!isRightDirection) {
    return [1, 0, currentPage - 1, currentPage, currentPage + 1, 0, maxPage];
  }
  return [];
}

export default function TablePagination(props) {
  const [isRightDirection, setIsRightDirection] = useState(true);

  const [buttonNumbers, setButtonNumbers] = useState([]);

  function ChangeCurrentPage(newPage) {
    props.setCurrentPage(newPage);
  }

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (props.currentPage === props.maxPage) {
        setIsRightDirection(false);
      }
      if (props.currentPage === 1) {
        setIsRightDirection(true);
      }
      var res = GetButtonNumbers(
        props.currentPage,
        props.maxPage,
        isRightDirection
      );
      setButtonNumbers(res);
    }
    return () => {
      isCancelled = true;
    };
  }, [props.currentPage, props.maxPage, isRightDirection]);

  return (
    <div className="py-3 flex items-center justify-between border-t border-gray-200">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          onClick={() => {
            if (props.currentPage === 1) return;
            props.setCurrentPage((prev) => parseInt(prev) - 1);
          }}
          className="relative inline-flex items-center px-4 py-2 border-2 border-gray-900 text-sm font-medium rounded-none text-gray-900 bg-unicutePurple-100 bg-opacity-50 hover:bg-unicutePurple-200"
        >
          Previous
        </button>
        <button
          onClick={() => {
            if (props.maxPage === props.currentPage) return;
            props.setCurrentPage((prev) => prev + 1);
          }}
          className="ml-3 relative inline-flex items-center px-4 py-2 border-2 border-gray-900 text-sm font-medium rounded-none text-gray-900 bg-unicutePurple-100 bg-opacity-50 hover:bg-unicutePurple-200"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm font-medium text-gray-900">
            Page <span className="font-medium">{props.currentPage}</span> of{" "}
            <span className="font-medium">{props.maxPage}</span>
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-none shadow-sm -space-x-px shadow-retro"
            aria-label="Pagination"
          >
            <div></div>
            <button
              onClick={() => {
                if (props.currentPage === 1) return;
                props.setCurrentPage((prev) => parseInt(prev) - 1);
              }}
              className={
                props.currentPage === 1
                  ? PreButtonInactiveStyle()
                  : PreButtonStyle()
              }
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {props.currentPage > 0 &&
              buttonNumbers.length > 0 &&
              buttonNumbers.map((number) =>
                number === 0 ? (
                  <span
                    key={Math.random().toString()}
                    className={ButtonStyle()}
                  >
                    ...
                  </span>
                ) : (
                  <div
                    key={Math.random().toString()}
                    onClick={() => {
                      ChangeCurrentPage(number);
                    }}
                    aria-current="page"
                    className={
                      number === props.currentPage
                        ? ActiveButtonStyle()
                        : ButtonStyle()
                    }
                  >
                    {number}
                  </div>
                )
              )}
            <button
              onClick={() => {
                if (props.maxPage === props.currentPage) return;
                props.setCurrentPage((prev) => prev + 1);
              }}
              className={
                props.maxPage === props.currentPage
                  ? NextButtonInactiveStyle()
                  : NextButtonStyle()
              }
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
