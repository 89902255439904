import LazyLoad from "react-lazyload";
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  CdnGhostUnicutesImageLocation,
  CdnImageLocation,
  CdnWorldOfUnicutesImageLocation,
  GetSmartContractSubStateWithoutAddr,
  UnicutesMarketplaceContract,
  WorldOfUnicutesContract,
} from "../../utils/ZilliqaUtils.js";
import { ErrorNotification } from "../../components/notification/Notification";
import UnicuteDetailsPage from "../myUnicutes/unicutes/details/UnicuteDetailsPage";
import WalletContext from "../../components/contexts/WalletContext";
import { PrimaryButtonStyle } from "../../components/button/ButtonStyle";
import SmoothImage from "react-smooth-image";
import { SetUnicuteId } from "../../utils/UnicuteUtils.js";
import TablePagination from "../../components/table/TablePagination.js";
import Tab from "../../components/tab/Tab.js";
import {
  GetGhostUnicuteEnrichedTokens,
  GetGhostUnicuteOwnerTokens,
  GetGhostUnicuteTokens,
  GetGhostUnicuteTokenUris,
} from "../../data/smartcontract/ghostUnicutes/GetOwnerTokens.js";
import { calculateTotalPages } from "../../utils/GeneralUtils.js";
import SimpleImagePopup from "../../components/popup/SimpleImagePopup.js";
import { GetTokenUris } from "../../data/smartcontract/worldOfUnicutes/GetTokenUris.js";

const tabs = [
  { name: "Unicutes", href: "/explore/unicutes" },
  { name: "Ghost unicutes", href: "/explore/ghost-unicutes", count: "👻🎃" },
  { name: "World of unicutes", href: "/explore/world-of-unicutes" },
];

export default function Explore() {
  const query = new URLSearchParams(window.location.search);
  const { unicuteId = null, search = null, type = "unicutes" } = useParams();
  const [tabName, setTabName] = useState(tabs[0].name);
  const { setShowUnicuteWithId, showUnicuteWithId } = useContext(WalletContext);
  const [unicutes, setUnicutes] = useState(null);
  const [showGhostUnicuteImagePopup, setShowGhostUnicuteImagePopup] =
    useState(false);
  const [ghostUnicuteImageUrl, setGhostUnicuteImageUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    parseInt(query.get("page") ?? 1)
  );
  const [triggerRefreshPage, setTriggerRefreshPage] = useState(null);
  const [searchInput, setSearchInput] = useState(search);
  const [activeBids, setActiveBids] = useState([]);
  const [ghostUnicutes, setGhostUnicutes] = useState(null);
  const [displayGhostUnicutes, setDisplayGhostUnicutes] = useState(null);
  const [worldOfUnicutes, setWorldOfUnicutes] = useState(null);
  const [showWorldOfUnicutePopup, setShowWorldOfUnicutePopup] = useState(false);
  const [worldOfUnicuteImageUrl, setWorldOfUnicuteImageUrl] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    GetSmartContractSubStateWithoutAddr(
      UnicutesMarketplaceContract(),
      "token_bidders"
    ).then((res) => {
      if (res === null) {
        return;
      }
      let items = [];
      for (const [key, value] of Object.entries(res)) {
        if (value === null || value === undefined) continue;
        if (Object.keys(value).length !== 0) {
          items.push(key);
        }
      }

      setActiveBids(items);
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    if (searchInput === null) {
      return;
    }
    setCurrentPage(1);
    if (searchInput === "") {
      setTriggerRefreshPage(Math.random());
      setDisplayGhostUnicutes(ghostUnicutes);
      return;
    }
    if (tabName.toLowerCase() === "ghost-unicutes") {
      setDisplayGhostUnicutes(
        ghostUnicutes.filter((x) => x.id === searchInput.toString())
      );
    } else {
      axios
        .get(
          "https://backend.unicutes.app/api/Unicute/SearchById?Id=" +
            searchInput.toString()
        )
        .then((response) => {
          if (!isCancelled) {
            setUnicutes(response.data);
          }
        })
        .catch((error) =>
          ErrorNotification(
            "Failed to search for unicutes",
            "The webserver returned: " + error
          )
        );
    }

    return () => {
      isCancelled = true;
    };
  }, [searchInput, tabName]);
  useEffect(() => {
    if (currentPage === null) {
      setCurrentPage(1);
    }
    if (searchInput !== "")
    {
      return;
    }
    if (tabName.toLowerCase() !== "unicutes") {
      return;
    }
    // GET request using axios inside useEffect React hook
    axios
      .get(
        "https://backend.unicutes.app/api/Unicute/Search?page=" +
          (currentPage === null ? "1" : currentPage.toString())
      )
      .then((response) => {
        setUnicutes(response.data);
      })
      .catch((error) =>
        ErrorNotification(
          "Failed to search for unicutes",
          "The webserver returned: " + error
        )
      );

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [currentPage, triggerRefreshPage, tabName]);

  useEffect(() => {
    let isCancelled = false;
    !isCancelled && setSearchInput("");


    if (tabName.toLowerCase() === "world-of-unicutes") {
      !isCancelled &&
        GetTokenUris().then((res) => {
          if (res === null) {
            return;
          }
          setWorldOfUnicutes(res);
        });
    } else if (tabName.toLowerCase() === "ghost-unicutes") {
      !isCancelled &&
        GetGhostUnicuteTokens().then((tokens) => {
          console.log(tokens);
          !isCancelled &&
            GetGhostUnicuteTokenUris().then((uris) => {
              let enrichedTokens = GetGhostUnicuteEnrichedTokens(tokens, uris);
              setGhostUnicutes(enrichedTokens);
              setDisplayGhostUnicutes(enrichedTokens);
            });
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [tabName]);

  useEffect(() => {
    let isCancelled = false;
    if (isCancelled) {
      return;
    }
    setShowUnicuteWithId(unicuteId);
    return () => {
      isCancelled = true;
    };
  }, [unicuteId, setShowUnicuteWithId]);

  return (
    <>
      {unicuteId !== null && showUnicuteWithId !== null ? (
        <div>
          <UnicuteDetailsPage />
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-4 mt-4 mb-8">
          <div className="col-span-6">
            {" "}
            <Tab items={tabs} currentTab={type} setTabName={setTabName} />{" "}
          </div>
          {tabName !== "world-of-unicutes" ? (
            <div className="col-span-6 pb-4">
              <div className="mt-1 flex rounded-none shadow-retro">
                <input
                  value={searchInput}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    // if value is not blank, then test the regex
                    if (e.target.value === "") {
                      setSearchInput(e.target.value);
                    }
                    if (re.test(e.target.value)) {
                      if (parseInt(e.target.value) > 10000) {
                        return;
                      }
                      setSearchInput(e.target.value);
                    }
                  }}
                  type="text"
                  name="company-website"
                  id="company-website"
                  className="text-gray-900 flex-1 min-w-0 font-medium block w-full px-3 py-2 rounded-none focus:ring-0 focus:ring-indigo-500 focus:border-unicuteGreen-500 sm:text-sm border-2 border-gray-900"
                  placeholder="Search by token id"
                />
                <span className="bg-unicuteWarmPurple-100 inline-flex items-center px-3 rounded-none border-2 border-l-0 border-gray-900 text-gray-900 text-sm font-medium">
                  Search
                </span>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="col-span-12 text-left">
            <span className="text-gray-900 text-sm font-bold text-left">
              {tabName.toLowerCase() === "unicutes" ? (
                <>
                  Unicutes with a{" "}
                  <span className="bg-unicutePurple-400 p-1">purple</span>{" "}
                  background color have one or more active bids.{" "}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
          {tabName.toLowerCase() === "unicutes" ? (
            <>
              <div className="col-span-12 shadow-retro border-4 border-gray-900">
                <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 m-0 -mr-0.5 -mb-0.5">
                  {unicutes !== null &&
                    unicutes.items !== null &&
                    unicutes.items.map((unicute, i) => {
                      return (
                        <LazyLoad
                          key={unicute.id + "-lazy-" + i}
                          height={50 * (i === 1 ? 0 : i / 6)}
                        >
                          <NavLink
                            exact
                            to={
                              "/explore/" +
                              tabName.replace(/\s+/g, "-").toLowerCase() +
                              "/" +
                              unicute.smartContractId.toString() +
                              "/active-bids"
                            }
                            onClick={() =>
                              setShowUnicuteWithId(unicute.smartContractId)
                            }
                          >
                            <li
                              key={unicute.id + "-li-" + i}
                              className="relative"
                            >
                              <div
                                className={
                                  (activeBids.includes(
                                    unicute.smartContractId.toString()
                                  )
                                    ? "bg-unicutePurple-400 "
                                    : "bg-unicuteGreen-400 bg-opacity-20 ") +
                                  "cursor-pointer border-gray-900 border-r-2 border-b-2 block w-full aspect-w-10 aspect-h-7 rounded-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500"
                                }
                              >
                                <div className="p-1 h-full w-full transform transition duration-500 scale-125 hover:scale-140 group-hover:opacity-75">
                                  <SmoothImage
                                    style={{
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                    }}
                                    src={
                                      unicute.isImageCreated === true
                                        ? CdnImageLocation() +
                                          "-output/" +
                                          unicute.smartContractId +
                                          ".png"
                                        : "/00.png"
                                    }
                                    alt=""
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="absolute inset-0 focus:outline-none"
                                >
                                  <span className="sr-only">
                                    View details for {unicute.id}
                                  </span>
                                </button>

                                <div className="absolute top-0 left-0 pl-2 pt-1">
                                  <div className="text-gray-900 p-1 font-bold text-sm">
                                    #{SetUnicuteId(unicute.smartContractId)}
                                  </div>
                                </div>
                                <div className="absolute bottom-0 left-0 pl-2 pb-1">
                                  <div className="text-gray-900 p-1 font-bold text-xs">
                                    {unicute.isImageCreated
                                      ? ""
                                      : "A new unicute is coming soon"}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </NavLink>
                        </LazyLoad>
                      );
                    })}
                </ul>
              </div>
              <div className="col-span-12">
                <TablePagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  maxPage={unicutes?.totalPages ?? 1}
                />
              </div>
            </>
          ) : tabName.toLowerCase() === "ghost-unicutes" ? (
            <>
              <SimpleImagePopup
                imageUrl={ghostUnicuteImageUrl}
                showModal={showGhostUnicuteImagePopup}
                setShowModal={setShowGhostUnicuteImagePopup}
              />
              {displayGhostUnicutes === null ||
              displayGhostUnicutes.length === 0 ? (
                <div className="col-span-12 text-left">
                  <span className="text-gray-900 text-sm font-bold text-left">
                    <>No ghost unicutes found.</>
                  </span>
                </div>
              ) : (
                <>
                  <div className="col-span-12 shadow-retro border-4 border-gray-900">
                    <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 m-0 -mr-0.5 -mb-0.5">
                      {displayGhostUnicutes !== null &&
                        displayGhostUnicutes
                          .slice(
                            (currentPage - 1) * 18,
                            (currentPage - 1) * 18 + 18
                          )
                          .map((ghostUnicute, i) => {
                            return (
                              <LazyLoad
                                key={ghostUnicute.id + "-lazy-" + i}
                                height={50 * (i === 1 ? 0 : i / 6)}
                              >
                                <li
                                  key={ghostUnicute.id + "-li-" + i}
                                  className="relative"
                                >
                                  <div
                                    onClick={() => {
                                      setShowGhostUnicuteImagePopup(true);
                                      setGhostUnicuteImageUrl(
                                        CdnGhostUnicutesImageLocation() +
                                          ghostUnicute.uri.toString() +
                                          ".png"
                                      );
                                    }}
                                    className={
                                      "bg-unicuteGreen-400 bg-opacity-20 cursor-pointer border-gray-900 border-r-2 border-b-2 block w-full aspect-w-10 aspect-h-7 rounded-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500"
                                    }
                                  >
                                    <div className="h-full w-full overflow-hidden group-hover:opacity-75">
                                      <SmoothImage
                                        style={{
                                          backgroundPosition: "center",
                                          backgroundSize: "cover",
                                        }}
                                        src={
                                          CdnGhostUnicutesImageLocation() +
                                          ghostUnicute.uri.toString() +
                                          ".png"
                                        }
                                        alt={
                                          "ghost unicute #" + ghostUnicute.id
                                        }
                                      />
                                    </div>

                                    <div className="absolute top-0 left-0 pl-2 pt-1">
                                      <div className="text-gray-900 p-1 font-bold text-sm">
                                        #{ghostUnicute.id}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </LazyLoad>
                            );
                          })}
                    </ul>
                  </div>
                  <div className="col-span-12">
                    <TablePagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      maxPage={calculateTotalPages(
                        displayGhostUnicutes?.length ?? 1,
                        18
                      )}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <SimpleImagePopup
                imageUrl={worldOfUnicuteImageUrl}
                showModal={showWorldOfUnicutePopup}
                setShowModal={setShowWorldOfUnicutePopup}
              />
              {worldOfUnicutes === null || worldOfUnicutes.length === 0 ? (
                <div className="col-span-12 text-left">
                  <span className="text-gray-900 text-sm font-bold text-left">
                    <>No world of unicutes found.</>
                  </span>
                </div>
              ) : (
                <>
                  <div className="col-span-12 shadow-retro border-4 border-gray-900">
                    <ul className="grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-3 m-0">
                      {worldOfUnicutes !== null &&
                        worldOfUnicutes
                          .slice(
                            (currentPage - 1) * 3,
                            (currentPage - 1) * 3 + 3
                          )
                          .map((worldOfUnicute, i) => {
                            return (
                              <LazyLoad
                                key={worldOfUnicute.id + "-lazy-" + i}
                                height={50 * (i === 1 ? 0 : i / 3)}
                              >
                                <li
                                  key={worldOfUnicute.id + "-li-" + i}
                                  className="relative"
                                >
                                  <div
                                    onClick={() => {
                                      setShowWorldOfUnicutePopup(true);
                                      setWorldOfUnicuteImageUrl(
                                        CdnWorldOfUnicutesImageLocation() +
                                          worldOfUnicute.uri.toString() +
                                          ".png"
                                      );
                                    }}
                                    className={
                                      "bg-unicuteGreen-400 bg-opacity-20 cursor-pointer block w-full aspect-w-10 aspect-h-7 rounded-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500"
                                    }
                                  >
                                    <div className="h-full w-full overflow-hidden group-hover:opacity-75">
                                      <SmoothImage
                                        style={{
                                          backgroundPosition: "center",
                                          backgroundSize: "cover",
                                        }}
                                        src={
                                          CdnWorldOfUnicutesImageLocation() +
                                          worldOfUnicute.uri.toString() +
                                          ".png"
                                        }
                                        alt={
                                          "world of unicute #" + i.toString()
                                        }
                                      />
                                    </div>

                                    <div className="absolute top-0 left-0 pl-2 pt-1">
                                      <div className="text-gray-900 p-1 font-bold text-sm"></div>
                                    </div>
                                  </div>
                                </li>
                              </LazyLoad>
                            );
                          })}
                    </ul>
                  </div>
                  <div className="col-span-12">
                    <TablePagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      maxPage={calculateTotalPages(
                        worldOfUnicutes?.length ?? 1,
                        3
                      )}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
