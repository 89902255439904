import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ModalStyle } from "../../../../components/modal/ModalStyle";
import Emoji from "react-emoji-render";
import { PrimaryButtonStyle } from "../../../../components/button/ButtonStyle";
import GetTokenApprovals from "../../../../data/smartcontract/unicutes/GetTokenApprovals";
import { ProxyGhostUnicutesContract } from "../../../../utils/ZilliqaUtils";
import {
  InformationNotification,
  SuccessNotification,
} from "../../../../components/notification/Notification";
import {
  ApproveUnicuteBurningButtonFragment,
  BurnUnicuteButtonFragment,
} from "./BurnModalActions";
import {
  InteractWithContractBurn,
  InteractWithContractSetApprove,
} from "../../../../utils/ZilPayUtils";
import WalletContext from "../../../../components/contexts/WalletContext";

export function BurnModal(props) {
  const { setTriggerOwnedUnicutes } = useContext(WalletContext);
  const [isDeletingExistingTokenSpender, setIsDeletingExistingTokenSpender] =
    useState(false);
  const [existingTokenSpender, setExistingTokenSpender] = useState(null);
  const [
    isDeletingExistingTokenSpenderConfirming,
    setIsDeletingExistingTokenSpenderConfirming,
  ] = useState(false);

  const [isBurningApproved, setIsBurningApproved] = useState(false);
  const [isApproveBurningConfirming, setIsApproveBurningConfirming] =
    useState(false);
  const [isBurningApproverRetrieved, setIsBurningApproverRetrieved] =
    useState(false);
  const [isBurningConfirming, setIsBurningConfirming] = useState(false);

  function BurnUnicuteCallback() {
    setIsBurningConfirming(true);
  }

  function ApproveBurnCallback() {
    setIsApproveBurningConfirming(true);
  }

  function DeleteApproverCallback() {
    setIsDeletingExistingTokenSpenderConfirming(true);
  }

  function IsTokenReadyForTransfer(isCancelled, tokenId) {
    !isCancelled &&
      GetTokenApprovals(tokenId).then((spender) => {
        if (isBurningApproved && spender === null) {
          !isCancelled &&
            SuccessNotification(
              "Haunted unicute burned",
              "You have successfully burned unicute #" +
                tokenId.toString() +
                ". A wild ghost unicute is about to appear."
            );
          !isCancelled && setTriggerOwnedUnicutes(Math.random());
          !isCancelled && props.setShowModal(false);
        }

        if (spender === null || isBurningApproved) {
          if (isDeletingExistingTokenSpender) {
            SuccessNotification(
              "Approver deleted",
              "Now you can begin the burning of unicute #" + tokenId.toString()
            );
            setIsDeletingExistingTokenSpender(false);
          }
          return;
        } else {
          if (spender === ProxyGhostUnicutesContract().toLowerCase()) {
            !isCancelled && setIsApproveBurningConfirming(false);
            !isCancelled && setIsBurningApproved(true);
            !isCancelled &&
              InformationNotification(
                "Unicute burn approved",
                "Now you can burn unicute #" + tokenId.toString()
              );
          } else if (
            spender !== ProxyGhostUnicutesContract().toLowerCase() &&
            spender.trim() !== ""
          ) {
            // Another address has been set as approver
            setIsDeletingExistingTokenSpender(true);
            setExistingTokenSpender(spender);
          }
          !isCancelled && setIsBurningApproverRetrieved(true);
        }
      });
  }
  useEffect(() => {
    let isCancelled = false;
    if (
      !props.showModal ||
      !isDeletingExistingTokenSpenderConfirming ||
      !isDeletingExistingTokenSpender
    ) {
      return;
    }

    const interval = setInterval(() => {
      if (
        !isCancelled &&
        (!isDeletingExistingTokenSpenderConfirming ||
          !isDeletingExistingTokenSpender)
      ) {
        return;
      } else {
        !isCancelled && IsTokenReadyForTransfer(isCancelled, props.tokenId);
      }
    }, 10000);

    return () => {
      isCancelled = true;
      clearInterval(interval);
    };
  }, [
    isDeletingExistingTokenSpenderConfirming,
    isDeletingExistingTokenSpender,
    props.showModal,
  ]);

  useEffect(() => {
    let isCancelled = false;
    // start reset
    !isCancelled && setIsBurningConfirming(false);
    !isCancelled && setIsApproveBurningConfirming(false);
    !isCancelled && setIsBurningApproverRetrieved(false);
    !isCancelled && setIsDeletingExistingTokenSpenderConfirming(false);
    !isCancelled && setIsBurningApproverRetrieved(false);
    !isCancelled && setIsDeletingExistingTokenSpender(false);
    !isCancelled && setExistingTokenSpender(null);
    // end reset
    if (
      props.tokenId === null ||
      props.tokenId === undefined ||
      !props.showModal
    ) {
      return;
    }

    !isCancelled && IsTokenReadyForTransfer(isCancelled, props.tokenId);

    return () => {
      isCancelled = true;
    };
  }, [props.tokenId, props.showModal]);

  useEffect(() => {
    let isCancelled = false;
    if (!isBurningConfirming && !isApproveBurningConfirming) {
      return;
    }
    if (!props.showModal) {
      return;
    }
    const interval = setInterval(() => {
      console.log("searching");
      !isCancelled && IsTokenReadyForTransfer(isCancelled, props.tokenId);
    }, 10000);

    return () => {
      isCancelled = true;

      clearInterval(interval);
    };
  }, [isBurningConfirming, isApproveBurningConfirming, props.showModal]);

  return (
    <Transition.Root show={props.showModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={props.setShowModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={ModalStyle()}>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 p-6">
                <button
                  onClick={() => props.setShowModal(false)}
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-indigo-500"
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  <Emoji text={"Burn unicute #" + props.tokenId + " 🔥👻"} />
                </h2>
                {isDeletingExistingTokenSpender &&
                existingTokenSpender !== null ? (
                  <>
                    <p className="mt-2 max-w-2xl text-xs font-medium text-gray-500">
                      First, remove current approver ({existingTokenSpender})
                      for unicute #{props.tokenId}, and after that, you can
                      begin the burning process.
                    </p>
                    <div className="col-span-6 pt-4">
                      <div className="pb-2">
                        <ApproveUnicuteBurningButtonFragment
                          interactWithContractToApproveTransfer={
                            InteractWithContractSetApprove
                          }
                          successCallback={DeleteApproverCallback}
                          tokenId={props.tokenId}
                          isBurningApproved={false}
                          isApproveBurningConfirming={
                            isDeletingExistingTokenSpenderConfirming
                          }
                          text1={"Delete approver"}
                          text2={"Deleting approver"}
                          text3={"Approver deleted"}
                          spender={existingTokenSpender.toString()}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="mt-2 max-w-2xl text-xs font-medium text-gray-500">
                      First, approve the burn of unicute #{props.tokenId}, and
                      after that, you can burn it.
                    </p>
                    <div className="col-span-6 pt-4">
                      <div className="pb-2">
                        <ApproveUnicuteBurningButtonFragment
                          interactWithContractToApproveTransfer={
                            InteractWithContractSetApprove
                          }
                          successCallback={ApproveBurnCallback}
                          tokenId={props.tokenId}
                          isBurningApproved={isBurningApproved}
                          isApproveBurningConfirming={
                            isApproveBurningConfirming
                          }
                          text1={"Approve unicute burning"}
                          text2={"Approving unicute burning"}
                          text3={"Unicute burning approved"}
                          spender={ProxyGhostUnicutesContract().toString()}
                        />
                      </div>
                      <BurnUnicuteButtonFragment
                        interactWithContractToBurnUnicute={
                          InteractWithContractBurn
                        }
                        successCallback={BurnUnicuteCallback}
                        tokenId={props.tokenId}
                        isBurningApproved={isBurningApproved}
                        isBurningConfirming={isBurningConfirming}
                      />
                    </div>
                    <p className="mt-2 max-w-2xl text-xs font-medium text-gray-500">
                      A burned unicute can't be recovered. It's an irreversible action.
                    </p>
                  </>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
