import {
  GetSmartContractSubStateWithoutAddr,
  UnicutesMarketplaceContract,
  WorldOfUnicutesContract,
} from "../../../utils/ZilliqaUtils";

export function GetTokenOwners() {
  return new Promise((resolve, reject) => {
    let tokenOwners = [];
    GetSmartContractSubStateWithoutAddr(
      WorldOfUnicutesContract(),
      "token_owners"
    ).then((response) => {
      if (response === null) {
        return resolve(tokenOwners);
      }

      for (const [key, value] of Object.entries(response)) {
        var tokenOwner = {
          id: key,
          owner: value
        };
        tokenOwners.push(tokenOwner);
      }
      return resolve(tokenOwners);
    });
  });
}