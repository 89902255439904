import { useContext, useEffect, useState } from "react";
import { GetTokenHistory } from "../../data/smartcontract/unicutesMarket/GetTokenHistory";
import WalletContext from "../contexts/WalletContext";
import BidsTable from "./BidsTable";

export default function HistoricBidsTable(props) {
    const {triggerBids, account} = useContext(WalletContext)
    const [tokenHistory, setTokenHistory] = useState([]);
    useEffect(() => {
        let isCancelled = false;
        !isCancelled && GetTokenHistory(props.unicuteId).then((items) => {
            !isCancelled && setTokenHistory(items);
        })
        return () => {
            isCancelled = true;
        }
    }, [props.unicuteId, triggerBids, account])

    return (
        <>
        <BidsTable records={tokenHistory} disableAction={true} noRecordsMessage={"No historic bids found."}/>
        </>
    )
}