import React, { useEffect, useState } from "react";

export default function Timer(props) {
  const calculateTimeLeft = () => {
    let year = props.time;
    const difference = year - new Date();
    let timeLeft = {};

    if (difference > 0) {
      var days = Math.floor(difference / (1000 * 60 * 60 * 24));
      var hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      var minutes = Math.floor((difference / 1000 / 60) % 60);
      var seconds = Math.floor((difference / 1000) % 60);
      if (days < 10) {
        days = "0" + days;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      timeLeft = {
        days,
        hours,
        minutes,
        seconds,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date());

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => {
      clearTimeout(timer1);
    };
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]}
        {interval == "seconds" ? "" : ":"}
      </span>
    );
  });

  return (
    <div key={props.id + "-timer"}>
      {timerComponents.length ? (
        timerComponents
      ) : (
        <span> {props.countdownEndedMessage} </span>
      )}
    </div>
  );
}
