import {
  GetSmartContractSubStateWithoutAddr,
  UnicutesMarketplaceContract,
} from "../../../utils/ZilliqaUtils";

export function GetTokensBidders(tokenIds, account) {
  return new Promise((resolve, reject) => {
    let items = [];
    if (account === null ||tokenIds.length === 0) {
      return resolve(items);
    }
    GetSmartContractSubStateWithoutAddr(
      UnicutesMarketplaceContract(),
      "token_bidders"
    ).then((response) => {
      if (response === null) {
        return resolve(items);
      }
      tokenIds.forEach((elem) => {
        let tokenBids = response[elem];
        console.log(tokenBids[account.base16.toLowerCase()]);
        var tokenBid = {
          id: elem,
          address: account.base16.toLowerCase(),
          amount: tokenBids[account.base16.toLowerCase()],
        };
        items.push(tokenBid);
      });
      return resolve(items);
    });
  });
}

export function GetTokenBidders(tokenId) {
  return new Promise((resolve, reject) => {
    if (tokenId === null || tokenId === undefined) {
      return resolve([]);
    }
    GetSmartContractSubStateWithoutAddr(
      UnicutesMarketplaceContract(),
      "token_bidders",
      [tokenId.toString()]
    ).then((response) => {
      let items = [];
      if (response === null) {
        return resolve(items);
      }
      let tokenBiddersResponse = response[tokenId.toString()];
      if (tokenBiddersResponse === null || tokenBiddersResponse === undefined) {
        return resolve(items);
      }
      for (const [key, value] of Object.entries(tokenBiddersResponse)) {
        var tokenBid = {
          address: key.toString(),
          amount: value.toString(),
        };
        items.push(tokenBid);
      }
      return resolve(items);
    });
  });
}
