import reactSmoothImage from "react-smooth-image";
import { CdnGhostUnicutesImageLocation } from "../../../utils/ZilliqaUtils";

export default function GhostUnicutesSection(props) {
  return (
    <>
      <div className="grid grid-cols-12 gap-4 mt-4 mb-8">
        <div className="col-span-12 text-left">
          <span className="text-gray-900 text-sm font-bold text-left">
          </span>
        </div>
        {props.ghostUnicutes === null ||
        props.ghostUnicutes === undefined ||
        props.ghostUnicutes.length === 0 ? (
          <div className="col-span-12">
            <span className="text-gray-900 text-sm font-medium">
              No ghost unicutes found.
            </span>
          </div>
        ) : (
          <div className="col-span-12 shadow-retro border-4 border-gray-900">
            <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 m-0 -mr-0.5 -mb-0.5">
              {props.ghostUnicutes !== null &&
                props.ghostUnicutes.map((ghostUnicute) => {
                  return (
                    <li
                      key={ghostUnicute.id}
                      className="relative cursor-pointer"
                    >
                      <div
                        className={
                          "cursor-pointer border-gray-900 border-r-2 border-b-2 block w-full aspect-w-10 aspect-h-7 rounded-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500"
                        }
                      >
                        <img
                          src={CdnGhostUnicutesImageLocation() + ghostUnicute.uri.toString() + ".png"}
                          alt={"ghost unicute #" + ghostUnicute.id}
                          style={{
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            overflow: "hidden",
                          }}
                          className="overflow-hidden h-full w-full pointer-events-none group-hover:opacity-75"
                        />
                        <button
                          type="button"
                          className="absolute inset-0 focus:outline-none"
                        >
                          <span className="sr-only">View details for </span>
                        </button>
                        <div className="absolute top-0 left-0 pl-2 pt-1">
                          <div className="text-gray-900 p-1 font-bold text-sm">
                            #{ghostUnicute.id}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
