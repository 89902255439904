import { UnicutesMarketplaceContract } from "../../../utils/ZilliqaUtils";
import WaitingAnimation from "../../animations/WaitingAnimation";
import {
  InactivePrimaryButtonStyle,
  PrimaryButtonStyle,
} from "../../button/ButtonStyle";

export const AcceptBidFragment = ({
  interactWithContractToAcceptBid,
  successCallback,
  tokenId,
  tokenName,
  address,
  amount,
  isTransferApproved,
  isBidAcceptConfirming,
}) => (
  <>
    <button
      onClick={() => {
        if (!isTransferApproved || isBidAcceptConfirming) {
          return;
        }
        interactWithContractToAcceptBid(
          tokenId,
          address,
          amount,
          successCallback
        );
      }}
      className={
        isTransferApproved && !isBidAcceptConfirming
          ? PrimaryButtonStyle("")
          : InactivePrimaryButtonStyle("")
      }
    >
      {isTransferApproved && isBidAcceptConfirming ? (
        <>
          <WaitingAnimation />
          Accepting bid
        </>
      ) : (
        "Accept bid"
      )}
    </button>
  </>
);

export const ApproveTransferFragment = ({
  interactWithContractToApproveTransfer,
  successCallback,
  tokenId,
  tokenName,
  isTransferApproved,
  isApprovingConfirming,
}) => (
  <>
    <button
      onClick={() => {
        if (isTransferApproved || isApprovingConfirming) {
          return;
        }
        interactWithContractToApproveTransfer(tokenId, successCallback, UnicutesMarketplaceContract().toString());
      }}
      className={
        isTransferApproved || isApprovingConfirming
          ? InactivePrimaryButtonStyle("")
          : PrimaryButtonStyle("")
      }
    >
      {isApprovingConfirming ? (
        <>
          <WaitingAnimation />
          Approving {tokenName.toLowerCase()} transfer
        </>
      ) : isTransferApproved ? (
        tokenName + " transfer approved"
      ) : (
        "Approve " + tokenName.toLowerCase() + " transfer"
      )}
    </button>
  </>
);
