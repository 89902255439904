import { useContext, useEffect, useState } from "react";
import { GetTokenBidders } from "../../data/smartcontract/unicutesMarket/GetTokenBidders";
import {
  bidAccepted,
  bidOpen,
  GetTokenHistory,
} from "../../data/smartcontract/unicutesMarket/GetTokenHistory";
import WalletContext from "../contexts/WalletContext";
import BidsTable from "./BidsTable";

function SortBidsByHighestAmount(bids) {
  return bids.sort((a, b) => b.amount - a.amount);
}

export default function ActiveBidsTable(props) {
  const { triggerBids, account } = useContext(WalletContext);
  const [activeBids, setActiveBids] = useState([]);
  const [tokenHistory, setTokenHistory] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    !isCancelled &&
      GetTokenBidders(props.unicuteId).then((items) => {
        !isCancelled && setActiveBids(items);
      });
    return () => {
      isCancelled = true;
    };
  }, [props.unicuteId, triggerBids, account]);

  useEffect(() => {
    let isCancelled = false;
    !isCancelled &&
      GetTokenHistory(props.unicuteId).then((items) => {
        !isCancelled && setTokenHistory(items);
      });
    return () => {
      isCancelled = true;
    };
  }, [activeBids]);

  useEffect(() => {
    let isCancelled = false;
    let items = [];
    !isCancelled &&
      activeBids.forEach((item) => {
        if (isCancelled) {
          return;
        }
        var index = tokenHistory.findIndex(
          (x) =>
            x.address === item.address &&
            x.state === bidOpen &&
            x.state !== bidAccepted
        );
        if (index !== -1) {
          let record = tokenHistory[index];
          items.push(record);
        }
      });
    !isCancelled && setRecords(SortBidsByHighestAmount(items));
    return () => {
      isCancelled = true;
    };
  }, [tokenHistory]);
  return (
    <>
      <BidsTable records={records} noRecordsMessage={"No active bids found."} />
    </>
  );
}
