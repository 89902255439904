import { useState, useEffect, useContext, useRef } from "react";
import EggDetailsModal from "./eggs/EggDetailsModal";
import MyUnicutesContext from "../../components/contexts/MyUnicutesContext";
import Tab, { tabEquals } from "../../components/tab/Tab";
import EggsSection from "./eggs/EggsSection";
import { useParams } from "react-router-dom";
import UnicutesSection from "./unicutes/UnicutesSection";
import UnicuteDetailsPage from "./unicutes/details/UnicuteDetailsPage";
import {
  GetSmartContractSubStateWithoutAddr,
  UnicutesMarketplaceContract,
} from "../../utils/ZilliqaUtils";
import WalletContext from "../../components/contexts/WalletContext";
import { GetTokenBidders } from "../../data/smartcontract/unicutesMarket/GetTokenBidders";
import MyBidsTable from "../../components/bidsTable/MyBidsTable";
import { GetBidderTokens } from "../../data/smartcontract/unicutesMarket/GetBidderTokens";
import {
  GetGhostUnicuteEnrichedTokens,
  GetGhostUnicuteOwnerTokens,
  GetGhostUnicuteTokens,
  GetGhostUnicuteTokenUris,
} from "../../data/smartcontract/ghostUnicutes/GetOwnerTokens";
import GhostUnicutesSection from "./ghostUnicutes/GhostUnicutesSection";
import { GetOwnedTokens } from "../../data/smartcontract/worldOfUnicutes/GetOwnedTokens";
import WorldOfUnicutesSection from "./worldOfUnicutes/WorldOfUnicutesSection";

export default function MyUnicutes() {
  const {
    isConnected,
    ownedEggs,
    ownedUnicutes,
    showUnicuteWithId,
    setShowUnicuteWithId,
  } = useContext(WalletContext);
  const { tab = "bids", unicuteId = null } = useParams();
  const [myEggs, setMyEggs] = useState(null);
  const [myUnicutes, setMyUnicutes] = useState(null);
  const [myGhostUnicutes, setMyGhostUnicutes] = useState(null);
  const [myWorldOfUnicutes, setMyWorldOfUnicutes] = useState(null);
  const [numberOfBids, setNumberOfBids] = useState(0);
  const { account } = useContext(WalletContext);
  const [tokenBidders, setTokenBidders] = useState([]);
  const tabs = [
    {
      name: "Eggs",
      href: "/my-unicutes/eggs",
      count: myEggs?.filter((x) => x.state === 1).length ?? 0,
    },
    {
      name: "Unicutes",
      href: "/my-unicutes/unicutes",
      count: myUnicutes?.length ?? 0,
    },
    { name: "Bids", href: "/my-unicutes/bids", count: numberOfBids },
    {
      name: "Ghost unicutes",
      href: "/my-unicutes/ghost-unicutes",
      count: myGhostUnicutes?.length ?? 0,
    },
    {
      name: "World of unicutes",
      href: "/my-unicutes/world-of-unicutes",
      count: myWorldOfUnicutes?.length ?? 0,
    },
  ];

  const [tabName, setTabName] = useState(tab);

  const [lockedEggs, setLockedEggs] = useState([0]);
  const lockedEggsRef = useRef(lockedEggs);
  useEffect(() => {
    lockedEggsRef.current = lockedEggs;
  });

  useEffect(() => {
    let isCancelled = false;
    if (account === null) {
      return;
    }
    !isCancelled &&
      GetOwnedTokens(account).then((res) => {
        setMyWorldOfUnicutes(res);
      });
    return () => {
      isCancelled = true;
    };
  }, [account]);

  useEffect(() => {
    let isCancelled = false;
    if (account === null) {
      return;
    }
    !isCancelled &&
      GetGhostUnicuteTokens().then((tokens) => {
        console.log(tokens);
        !isCancelled &&
          GetGhostUnicuteTokenUris().then((uris) => {
            console.log(uris);
            let enrichedTokens = GetGhostUnicuteEnrichedTokens(tokens, uris);
            console.log(enrichedTokens);
            let ownedGhostUnicutes = GetGhostUnicuteOwnerTokens(
              enrichedTokens,
              account
            );
            setMyGhostUnicutes(ownedGhostUnicutes);
          });
      });
    return () => {
      isCancelled = true;
    };
  }, [account]);

  useEffect(() => {
    setShowUnicuteWithId(unicuteId);
  }, [unicuteId, setShowUnicuteWithId]);

  useEffect(() => {
    let isCancelled = false;
    if (ownedUnicutes !== undefined) {
      const unicutes = [];
      for (const [key, value] of Object.entries(ownedUnicutes)) {
        if (isCancelled) {
          return;
        }
        var newUnicute = {
          id: key,
          drandRound: value.arguments[0],
          drandIndex: value.arguments[1],
          drandValue: value.arguments[2]?.arguments[0],
          isLegendary: value.arguments[3]?.arguments[0]?.constructor,
        };
        unicutes.push(newUnicute);
      }
      if (!isCancelled) {
        setMyUnicutes(unicutes);
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [ownedUnicutes]);

  useEffect(() => {
    let isCancelled = false;
    if (ownedEggs !== null) {
      const eggs = [];
      for (const [key, value] of Object.entries(ownedEggs)) {
        if (isCancelled) {
          return;
        }
        var newEgg = {
          id: key,
          createdAt: value.arguments[0],
          hatchableAt: value.arguments[1],
          state: value.arguments[2],
        };
        eggs.push(newEgg);
      }
      !isCancelled && setMyEggs(eggs);
    }
    return () => {
      isCancelled = true;
    };
  }, [ownedEggs]);

  useEffect(() => {
    let isCancelled = false;
    if (account === null || account === undefined || account.base16 === null) {
      return;
    }
    !isCancelled &&
      GetBidderTokens(account).then((items) => {
        !isCancelled && setNumberOfBids(items.length);
      });

    return () => {
      isCancelled = true;
    };
  }, [account, ownedUnicutes]);

  useEffect(() => {
    let isCancelled = false;
    GetSmartContractSubStateWithoutAddr(
      UnicutesMarketplaceContract(),
      "token_bidders"
    ).then((res) => {
      if (res === null || isCancelled) {
        return;
      }
      setTokenBidders(res);
    });
    return () => {
      isCancelled = true;
    };
  }, [account, ownedUnicutes]);

  const [modalEggDetailsOpen, setModalEggDetailsOpen] = useState(false);
  const [modalEggDetailsData, setModalEggDetailsData] = useState(null);

  const myUnicutesContextValue = {
    tabName,
    setTabName,
    showUnicuteWithId,
    setShowUnicuteWithId,
    modalEggDetailsOpen,
    setModalEggDetailsOpen,
    modalEggDetailsData,
    setModalEggDetailsData,
    setLockedEggs,
    lockedEggsRef,
    myUnicutes,
    setMyUnicutes,
  };

  return (
    <MyUnicutesContext.Provider value={myUnicutesContextValue}>
      <EggDetailsModal />

      <div className="mt-4">
        <Tab
          className="mt-4"
          items={tabs}
          currentTab={tabName}
          setTabName={setTabName}
        />
      </div>
      {!isConnected ? (
        <p className="text-sm font-medium text-gray-700 bg-red-100 p-2 mt-4">
          Please connect to a wallet to view your owned eggs, unicutes and
          received offers.
        </p>
      ) : (
        ""
      )}

      {tabEquals(tabName, tabs[0].name) ? <EggsSection eggs={myEggs} /> : ""}
      {tabEquals(tabName, tabs[1].name) && showUnicuteWithId === null ? (
        <UnicutesSection unicutes={myUnicutes} tokenBidders={tokenBidders} />
      ) : (
        ""
      )}
      {tabEquals(tabName, tabs[1].name) && showUnicuteWithId !== null ? (
        <UnicuteDetailsPage />
      ) : (
        ""
      )}
      {tabEquals(tabName, tabs[2].name) ? (
        <div className="pt-4">
          <MyBidsTable />
        </div>
      ) : (
        ""
      )}

      {tabEquals(tabName, tabs[3].name) ? (
        <GhostUnicutesSection ghostUnicutes={myGhostUnicutes} />
      ) : (
        ""
      )}

      {tabEquals(tabName, tabs[4].name) ? (
        <WorldOfUnicutesSection worldOfUnicutes={myWorldOfUnicutes} />
      ) : (
        ""
      )}
    </MyUnicutesContext.Provider>
  );
}
