import WaitingAnimation from "../../../../components/animations/WaitingAnimation";
import {
  InactivePrimaryButtonStyle,
  PrimaryButtonStyle,
} from "../../../../components/button/ButtonStyle";
import { ProxyGhostUnicutesContract } from "../../../../utils/ZilliqaUtils";

export const BurnUnicuteButtonFragment = ({
  interactWithContractToBurnUnicute,
  successCallback,
  tokenId,
  isBurningApproved,
  isBurningConfirming,
}) => (
  <>
    <button
      onClick={() => {
        if (!isBurningApproved || isBurningConfirming) {
          return;
        }
        interactWithContractToBurnUnicute(tokenId, successCallback);
      }}
      className={
        isBurningApproved && !isBurningConfirming
          ? PrimaryButtonStyle("")
          : InactivePrimaryButtonStyle("")
      }
    >
      {isBurningApproved && isBurningConfirming ? (
        <>
          <WaitingAnimation />
          Burning unicute
        </>
      ) : (
        "Burn unicute"
      )}
    </button>
  </>
);

export const ApproveUnicuteBurningButtonFragment = ({
  interactWithContractToApproveTransfer,
  successCallback,
  tokenId,
  isBurningApproved,
  isApproveBurningConfirming,
  text1,
  text2,
  text3,
  spender,
}) => (
  <>
    <button
      onClick={() => {
        if (isBurningApproved || isApproveBurningConfirming) {
          return;
        }
        interactWithContractToApproveTransfer(
          tokenId,
          successCallback,
          spender
        );
      }}
      className={
        isBurningApproved || isApproveBurningConfirming
          ? InactivePrimaryButtonStyle("")
          : PrimaryButtonStyle("")
      }
    >
      {isApproveBurningConfirming ? (
        <>
          <WaitingAnimation />
          {" " + text2.toString()}
        </>
      ) : isBurningApproved ? (
        text3.toString()
      ) : (
        text1.toString()
      )}
    </button>
  </>
);
