import { useState, useEffect, useRef } from "react";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import WalletModal from "./components/walletModal/WalletModal";
import WalletInstallModal from "./components/walletModal/WalletInstallModal";
import WalletContext from "./components/contexts/WalletContext";
import ConnectedWalletModal from "./components/walletModal/ConnectedWalletModal";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import MyUnicutes from "./pages/myUnicutes/MyUnicutes";
import UnicuteProperties from "./pages/unicuteProperties/UnicuteProperties";
import { Toaster } from "react-hot-toast";
import Footer from "./components/footer/Footer";
import {
  GetBalance,
  GetSmartcontractState,
  GetSmartContractInit,
  ProxyContract,
  eventLogSubscription,
  GetSmartContractSubState,
  EggContract,
  getBlockNumber,
  newBlockSubscription,
  eventLogUnicuteSubscription,
  UnicutesContract,
  GetSmartContractSubStateWithoutAddr,
  GetBech32Account,
} from "./utils/ZilliqaUtils.js";
import {
  InformationNotification,
  PendingNotification,
  SuccessNotification,
} from "./components/notification/Notification";
import TermsAndConditions from "./pages/termsAndConditions/TermsAndConditions";
import Banner from "./components/banner/Banner";
import Explore from "./pages/explore/Explore";
import Faq from "./pages/faq/Faq";
import { HubConnectionBuilder } from "@microsoft/signalr";
import Marketplace from "./pages/marketplace/Marketplace";
import Vote from "./pages/vote/Vote";
import { ConnectToWallet, isZilpayInstalled } from "./utils/ZilPayUtils";
import { GetGhostUnicutesMintedNumber } from "./data/smartcontract/ghostUnicutes/GetOwnerTokens";

export default function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [installModalOpen, setInstallModalOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [walletName, setWalletName] = useState(null);
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState("Loading...");
  const [eggPrice, setEggPrice] = useState(null);
  const [eggHatchPrice, setEggHatchPrice] = useState(null);
  const [totalEggs, setTotalEggs] = useState(null);
  const [eggsPurchased, setEggsPurchased] = useState(null);
  const [ownedEggs, setOwnedEggs] = useState(null);
  const [ownedUnicutes, setOwnedUnicutes] = useState([]);
  const [blockNumber, setBlockNumber] = useState(0);
  const [triggerOwnedEggs, setTriggerOwnedEggs] = useState(Math.random());
  const [triggerOwnedUnicutes, setTriggerOwnedUnicutes] = useState(
    Math.random()
  );
  const [showUnicuteWithId, setShowUnicuteWithId] = useState(null);
  const [totalPurchasedEggs, setTotalPurchasedEggs] = useState(null);
  const [totalGhostUnicutes, setTotalGhostUnicutes] = useState(null);
  const [randomUnicutes, setRandomUnicutes] = useState(null);
  const [connection, setConnection] = useState(null);
  const accountRef = useRef(account);
  const ownedEggsRef = useRef(ownedEggs);
  const ownedUnicutesRef = useRef(ownedUnicutes);
  const [showAcceptBidModal, setShowAcceptBidModal] = useState(false);
  const [acceptBidData, setAcceptBidData] = useState(null);
  const [triggerBids, setTriggerBids] = useState(Math.random());
  const walletContextValue = {
    totalGhostUnicutes,
    randomUnicutes,
    setRandomUnicutes,
    connection,
    setConnection,
    showUnicuteWithId,
    setShowUnicuteWithId,
    modalOpen,
    setModalOpen,
    installModalOpen,
    setInstallModalOpen,
    isConnected,
    setIsConnected,
    accountModalOpen,
    setAccountModalOpen,
    walletName,
    setWalletName,
    account,
    setAccount,
    balance,
    setBalance,
    eggPrice,
    setEggPrice,
    eggHatchPrice,
    setEggHatchPrice,
    totalEggs,
    setTotalEggs,
    eggsPurchased,
    setEggsPurchased,
    ownedEggs,
    setOwnedEggs,
    blockNumber,
    setBlockNumber,
    ownedUnicutes,
    setOwnedUnicutes,
    totalPurchasedEggs,
    showAcceptBidModal,
    setShowAcceptBidModal,
    acceptBidData,
    setAcceptBidData,
    triggerBids,
    setTriggerBids,
    triggerOwnedUnicutes,
    setTriggerOwnedUnicutes,
  };

  useEffect(() => {
    accountRef.current = account;
    ownedEggsRef.current = ownedEggs;
    ownedUnicutesRef.current = ownedUnicutes;
  });

  useEffect(() => {
    if (
      isZilpayInstalled() &&
      window.zilPay.wallet.isConnect &&
      window.zilPay.wallet.isEnable
    ) {
      ConnectToWallet(setModalOpen);
      window.zilPay.wallet.observableNetwork().subscribe(function (net) {
        SuccessNotification("ZilPay network change", "Connected to " + net);
      });
    }
    let isCancelled = false;

    eventLogUnicuteSubscription(
      UnicutesContract(),
      accountRef,
      setTriggerOwnedUnicutes
    );
    eventLogSubscription(EggContract(), accountRef, setTriggerOwnedEggs);

    return () => {
      isCancelled = true;
    };
  }, [isConnected]);

  useEffect(() => {
    let isCancelled = false;
    const newConnection = new HubConnectionBuilder()
      .withUrl("https://backend.unicutes.app/hubs/home")
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
    GetSmartcontractState(ProxyContract()).then((res) => {
      if (!isCancelled) {
        setEggPrice(res["egg_price"]);
        setEggHatchPrice(res["hatch_egg_price"]);
        setEggsPurchased(res["eggs_purchased"]);
      }
    });
    GetSmartContractSubStateWithoutAddr(EggContract(), "egg_count", null).then(
      (subState) => {
        !isCancelled && setTotalPurchasedEggs(subState);
      }
    );
    newBlockSubscription(setBlockNumber);
    GetSmartContractInit(ProxyContract()).then((res) => {
      !isCancelled && setTotalEggs(res[1].value);
    });
    getBlockNumber().then((blockTxNum) => {
      !isCancelled && setBlockNumber(blockTxNum);
    });
    GetGhostUnicutesMintedNumber().then((no) => {
      !isCancelled && setTotalGhostUnicutes(no);
    });
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    if (account !== null) {
      GetSmartContractSubState(
        UnicutesContract(),
        "owned_tokens",
        account
      ).then((subStateOne) => {
        if (!isCancelled) {
          GetSmartContractSubStateWithoutAddr(
            UnicutesContract(),
            "transit_token_owners"
          ).then((subStateTwo) => {
            if (!isCancelled) {
              var dict = {};
              for (const [key, value] of Object.entries(subStateTwo)) {
                if (
                  value.toString() === account.base16.toLowerCase().toString()
                ) {
                  dict[key] = {
                    arguments: [
                      null,
                      null,
                      { arguments: [null] },
                      { arguments: [{ constructor: false }, null, null, null] },
                    ],
                  };
                }
                //
              }
              setOwnedUnicutes(Object.assign({}, subStateOne, dict));
            }
          });
        }
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [account, triggerOwnedUnicutes]);

  useEffect(() => {
    let isCancelled = false;
    if ((window.zilPay?.wallet?.defaultAccount ?? null) === null) {
      return;
    }
    if (!isCancelled) {
      SuccessNotification(
        "Connected to ZilPay",
        "Address " + GetBech32Account(window.zilPay.wallet.defaultAccount)
      );
    }
    if (!isCancelled) {
      setAccount(window.zilPay.wallet.defaultAccount);
    }
    if (!isCancelled) {
      setIsConnected(true);
    }
    return () => {
      isCancelled = true;
    };
  }, [window.zilPay?.wallet?.defaultAccount?.base16 ?? null]);

  useEffect(() => {
    let isCancelled = false;
    GetBalance(account).then((newBalance) => {
      if (!isCancelled) {
        setBalance(newBalance);
      }
    });

    if (account !== null) {
      GetSmartContractSubState(EggContract(), "owned_eggs", account).then(
        (subState) => {
          if (!isCancelled) {
            setOwnedEggs(subState);
          }
        }
      );
    }
    return () => {
      isCancelled = true;
    };
  }, [account, triggerOwnedEggs, triggerOwnedUnicutes]);

  useEffect(() => {
    let isCancelled = false;
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on("StreamRandomUnicutes", (message) => {
            if (!isCancelled) {
              setRandomUnicutes(message);
            }
          });
          connection.on("NewUnicute", (message) => {
            if (!isCancelled) {
              if (message.state === "Values Inserted") {
                SuccessNotification(
                  "New unicute!",
                  "Unicute #" + message.id + " has been generated randomly.",
                  "See unicute",
                  "/explore/" + message.id.toString()
                );
              }
              if (ownedEggsRef.current !== null) {
                if (message.id in ownedEggsRef.current) {
                  if (message.state === "Recorded") {
                    InformationNotification(
                      "Unicute in queue",
                      "Unicute #" +
                        message.id +
                        " has been added to the generation queue. It may take a while."
                    );
                  }
                }
              }
              if (ownedUnicutesRef.current !== null) {
                if (message.id in ownedUnicutesRef.current) {
                  if (message.state === "Time Inserted") {
                    InformationNotification(
                      "Unicute got drand rounds",
                      "Unicute #" +
                        message.id +
                        " has been allocated 2 rounds from drand.",
                      "See unicute",
                      "/explore/" + message.id.toString()
                    );
                  }
                  if (message.state === "Values Inserted - Unconfirmed") {
                    PendingNotification(
                      "Unicute got drand values",
                      "Drand values for unicute #" +
                        message.id +
                        " is being confirmed by the blockchain.",
                      "See unicute",
                      "/explore/" + message.id.toString()
                    );
                  }

                  setTriggerOwnedUnicutes(Math.random());
                }
              }
            }
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
    return () => {
      isCancelled = true;
    };
  }, [connection]);

  return (
    <WalletContext.Provider value={walletContextValue}>
      <div className="App bg-gray-200 bg-opacity-50 text-white font-sans">
        <div>
          <Router>
            <Banner />
            <Navbar />
            <WalletModal />
            <WalletInstallModal />
            <ConnectedWalletModal />
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/my-unicutes/:tab?/:unicuteId?">
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <MyUnicutes />
              </div>
            </Route>
            <Route exact path="/explore/:type?/:unicuteId?/:showTab?">
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <Explore />
              </div>
            </Route>
            <Route exact path="/marketplace">
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <Marketplace />
              </div>
            </Route>
            <Route exact path="/traits/:trait?">
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <UnicuteProperties />
              </div>
            </Route>
            <Route exact path="/tac">
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <TermsAndConditions />
              </div>
            </Route>
            <Route exact path="/faq">
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <Faq />
              </div>
            </Route>
            <Route exact path="/voting-contest">
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <Vote />
              </div>
            </Route>
            <Toaster position="bottom-right" reverseOrder={false} gutter={8} />
          </Router>

          <Footer />
        </div>
      </div>
    </WalletContext.Provider>
  );
}
