import { useContext, useEffect, useState } from "react";
import { GetBidderTokens } from "../../data/smartcontract/unicutesMarket/GetBidderTokens";
import { GetTokensBidders } from "../../data/smartcontract/unicutesMarket/GetTokenBidders";
import {
  bidAccepted,
  bidOpen,
  GetTokenHistory,
} from "../../data/smartcontract/unicutesMarket/GetTokenHistory";
import WalletContext from "../contexts/WalletContext";
import BidsTable from "./BidsTable";
export default function MyBidsTable(props) {
  const { triggerBids, account } = useContext(WalletContext);
  const [bidderTokens, setBidderTokens] = useState([]);
  const [records, setRecords] = useState([]);
  useEffect(() => {
    let isCancelled = false;
    !isCancelled &&
      GetBidderTokens(account).then((items) => {
        !isCancelled && setBidderTokens(items);
      });
    return () => {
      isCancelled = true;
    };
  }, [triggerBids, account]);

  useEffect(() => {
    let isCancelled = false;
    !isCancelled &&
      GetTokensBidders(bidderTokens, account).then((items) => {
        !isCancelled && setRecords(items);
      });
    return () => {
      isCancelled = true;
    };
  }, [bidderTokens]);

  return (
    <>
      <BidsTable isMyBids={true} records={records} noRecordsMessage={"No bids have been placed by you."} />
    </>
  );
}
