import { PrimaryRetroButtonDesign } from "../button/ButtonStyle";
import { NavLink, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function tabEquals(tab1, tab2) {
  return (
    tab1.replace(/\s+/g, "-").toLowerCase() ===
    tab2.replace(/\s+/g, "-").toLowerCase()
  );
}
function tabExists(tabs, tabName) {
  return tabs.filter((e) => tabEquals(e.name, tabName)).length > 0;
}

export default function Tab(props) {
  const [currentTab, setCurrentTab] = useState(
    tabExists(props.items, props.currentTab)
      ? props.currentTab
      : props.items[0].name
  );

  let history = useHistory();

  useEffect(() => {
    let isCancelled = false;
    if (!tabExists(props.items, props.currentTab)) {
      !isCancelled && props.setTabName(props.items[0].name.replace(/\s+/g, "-").toLowerCase());
    } else {
      !isCancelled && props.setTabName(currentTab.replace(/\s+/g, "-").toLowerCase());
    }
    return () => {
      isCancelled = true;
    };
  }, [currentTab, props.currentTab]);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          onChange={(event) => setCurrentTab(event.target.value)}
          className={
            "block w-full font-medium text-sm cursor-pointer focus:outline-none focus:border-gray-900 focus:border-0 focus:ring-gray-900 focus:ring-0 border-gray-900 border-2 text-gray-900 rounded-none shadow-retro"
          }
          defaultValue={currentTab}
        >
          {props.items.map((tab) => (
            <option
              className="font-medium text-sm text-gray-900 cursor-pointer"
              key={tab.name}
            >
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {props.items.map((tab) => (
            <NavLink
              onClick={() => setCurrentTab(tab.name)}
              key={tab.name}
              to={tab.href}
              exact
              className={classNames(
                tab.name.replace(/\s+/g, "-").toLowerCase() ===
                  currentTab.replace(/\s+/g, "-").toLowerCase()
                  ? "bg-unicutePurple-400 text-gray-900 "
                  : "text-gray-900 bg-unicutePurple-100 bg-opacity-50 hover:text-gray-700",
                "px-3 py-2 font-medium text-sm " + PrimaryRetroButtonDesign()
              )}
              aria-current={
                tab.name.replace(/\s+/g, "-").toLowerCase() ===
                currentTab.replace(/\s+/g, "-").toLowerCase()
                  ? "page"
                  : undefined
              }
            >
              {tab.name}
              {tab.count != null ? (
                <span
                  className={classNames(
                    tab.name.replace(/\s+/g, "-").toLowerCase() ===
                      currentTab.replace(/\s+/g, "-").toLowerCase()
                      ? ""
                      : "",
                    "hidden ml-3 rounded-none font-bold border-2 -right-2 px-0.5 bg-gray-100 absolute -top-2 text-gray-900 border-gray-900 text-xs md:inline-block"
                  )}
                >
                  {tab.count}
                </span>
              ) : (
                ""
              )}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
}
