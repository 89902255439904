import { PrimaryButtonStyle } from "../../components/button/ButtonStyle";
import { InteractWithContractBuyEgg } from "../../utils/ZilPayUtils";
import React, { useContext } from "react";
import { CdnImageLocation, QaToZil } from "../../utils/ZilliqaUtils.js";
import WalletContext from "../../components/contexts/WalletContext";
import Emoji from "react-emoji-render";
import ActiveBidsTable from "../../components/bidsTable/ActiveBidsTable";
import SmoothImage from "react-smooth-image";

function initImages() {
  var rows = [];
  for (var i = 0; i < 24; i++) {
    rows.push(
      <div key={Math.random()} className="overflow-hidden">
        <img
          alt="unicute"
          style={{
            backgroundPosition: "center",
            backgroundSize: "cover",
            overflow: "hidden",
          }}
          className="overflow-hidden h-full w-full transform scale-150 hover:bg-unicuteGreen-900"
          src={"00.png"}
        />
      </div>
    );
  }
  return rows;
}

function shuffle(array) {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export default function HeaderSection() {
  const {
    randomUnicutes,
    setModalOpen,
    isConnected,
    eggPrice,
    totalEggs,
    eggsPurchased,
  } = useContext(WalletContext);

  return (
    <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
          <h1>
            <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
              Gotta Hatch 'Em All
            </span>

            <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
              <span className="block text-gray-900">
                A verifiable randomly generated
              </span>
              <span className="block text-unicutePink-600">
                digital art collection on Zilliqa
              </span>
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
            If you want to get your hands on a unicute, claim an egg and hatch
            it.
          </p>
          <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
            <form action="#" method="POST" className="mt-3 sm:flex">
              {isConnected ? (
                eggsPurchased !== null &&
                totalEggs !== null &&
                totalEggs - eggsPurchased === 0 ? (
                  <button
                    type="button"
                    className={PrimaryButtonStyle(
                      "mt-3 w-full px-6 py-3 font-medium sm:mt-0 lg:ml-3 justify-center lg:flex-shrink-0 lg:inline-flex lg:items-center lg:w-auto"
                    )}
                  >
                    Batch sold out.
                  </button>
                ) : (
                  <button
                    onClick={() => InteractWithContractBuyEgg(eggPrice)}
                    type="button"
                    className={PrimaryButtonStyle(
                      "mt-3 w-full px-6 py-3 font-medium sm:mt-0 lg:ml-3 justify-center lg:flex-shrink-0 lg:inline-flex lg:items-center lg:w-auto"
                    )}
                  >
                    <Emoji text="Claim a unicute egg 🥚" />
                  </button>
                )
              ) : (
                <button
                  onClick={() => setModalOpen(true)}
                  type="button"
                  className={PrimaryButtonStyle(
                    "mt-3 w-full px-6 py-3 font-medium sm:mt-0 lg:ml-3 justify-center lg:flex-shrink-0 lg:inline-flex lg:items-center lg:w-auto"
                  )}
                >
                  <Emoji text="Claim a unicute egg 🥚" />
                </button>
              )}
            </form>
          </div>
        </div>

        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div className="grid grid-cols-6 gap-0 border-gray-900 shadow-retro border-4 -ml-0.5 bg-gray-900">
              {randomUnicutes === null && initImages()}
              {randomUnicutes !== null &&
                shuffle(randomUnicutes.items)
                  .slice(0, 24)
                  .map((randomUnicute, i) => {
                    return (
                      <div key={i} className="overflow-hidden">
                        <img
                          alt="unicute"
                          style={{
                            backgroundColor: randomUnicute.backgroundColor
                          }}
                          className="h-full w-full object-fit overflow-hidden transform scale-150 hover:bg-unicuteGreen-900"
                          src={
                            randomUnicute.unicuteId === -1
                              ? "00.png"
                              : CdnImageLocation() +
                                "-output/" +
                                randomUnicute.unicuteSmartContractId +
                                ".png"
                          }
                        />
                      </div>
                    );
                  })}
            </div>
        </div>
      </div>
    </main>
  );
}
