import {
  GetSmartContractSubStateWithoutAddr,
  UnicutesMarketplaceContract,
} from "../../../utils/ZilliqaUtils";

export function GetBidderTokens(account) {
  return new Promise((resolve, reject) => {
    let tokens = [];
    if(account === null){
      return resolve(tokens);
    }
    let address = account.base16.toLowerCase();
    GetSmartContractSubStateWithoutAddr(
      UnicutesMarketplaceContract(),
      "bidder_tokens",
      [address]
    ).then((response) => {
      if (response === null) {
        return resolve(tokens);
      }

      for (const [key, value] of Object.entries(response[address])) {
        tokens.push(key);
      }
      return resolve(tokens);
    });
  });
}

export function IsBidPlaced(account, tokenId) {
  return new Promise((resolve, reject) => {
    GetBidderTokens(account).then((response) => {
      if (response.length === 0) {
        return resolve(false);
      }
      return resolve(response.includes(tokenId));
    });
  });
}
