export function GetRarity(totalParts, parts) {
  var rarity = CalculateRarity(totalParts, parts);
  if (rarity === 0) return "";
  if (rarity < 0.2) return "shadow-legendary";
  if (rarity < 5) return "shadow-epiq";
  if (rarity < 20) return "shadow-uncommon";
  return "";
}

export function CalculateRarity(totalParts, parts) {
  if (parts === 0) return 0;
  return parseFloat((100 / parseInt(totalParts)) * parseInt(parts)).toFixed(2);
}

export function GetRarityObject(rarity) {
  var undiscovered = {
    name: "Undiscovered",
    colorCode: "#bba14b",
    colorName: "Dark Gold",
  };
  var legendary = {
    name: "Legendary",
    colorCode: "#ff8000",
    colorName: "Orange",
  };
  var epic = { name: "Epic", colorCode: "#a335ee", colorName: "Purple" };
  var rare = { name: "Rare", colorCode: "#0070dd", colorName: "Blue" };
  var uncommon = { name: "Uncommon", colorCode: "#16bb00", colorName: "Green" };
  var common = { name: "Common", colorCode: "#111827	", colorName: "Black" };
  var unclassified = { name: "Unclassified", colorCode: "#111827	", colorName: "Black" };
  return unclassified;
  const poor = {
    name: "Poor",
    colorCode: "#9d9d9d",
    colorName: "Gray",
  };
  if (rarity === 0) return undiscovered;
  if (rarity > 1.3) return poor;
  if (rarity > 1.2) return common;
  if (rarity > 1.1) return uncommon;
  if (rarity > 1.0) return rare;
  if (rarity > 0.9) return epic;
  return legendary;
}

export function GetRarityColor(rarity) {}
