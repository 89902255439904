import {
  GetSmartContractSubStateWithoutAddr,
  UnicutesMarketplaceContract,
  WorldOfUnicutesContract,
} from "../../../utils/ZilliqaUtils";
import { GetTokenOwners } from "./GetTokenOwners";
import { GetTokenUris } from "./GetTokenUris";

export function GetOwnedTokens(account) {
  return new Promise((resolve, reject) => {
    let ownedTokens = [];
    if(account === null){
      return resolve(ownedTokens);
    }
    let address = account.base16.toLowerCase();
    GetTokenOwners().then((tokenOwners) => {
      if(tokenOwners === null)
      {
        return resolve(ownedTokens);
      }
      else
      {
        GetTokenUris().then((tokenUris) => {
          if(tokenUris === null) 
          {
            return resolve(ownedTokens);
          }
          else
          {
            tokenOwners.forEach(elem => {
              if(elem.owner.toLowerCase() === address)
              {
                tokenUris.forEach(uris => {
                  if(uris.id === elem.id)
                  {
                    ownedTokens.push(uris.uri);
                  }
                })
              }
            });
            return resolve(ownedTokens);
          }
        });
      }
    });
  });
}