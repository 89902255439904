import { useEffect, useState } from "react";
import TablePagination from "./TablePagination";

function IsBidder(account, bidderAddr) {
  if ((account?.base16 ?? null) === null) {
    return false;
  }
  return account.base16.toLowerCase() === bidderAddr.toLowerCase();
}

export default function Table(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (props.records.length === 0) {
      setRecords([]);
      return;
    }
    let pageSize = 5;
    let calcMaxPage = Math.ceil(props.records.length / pageSize);
    setMaxPage(calcMaxPage);
    let start = (currentPage - 1) * pageSize;
    let result = props.records.slice(start, start + pageSize);
    !isCancelled && setRecords(result);
    return () => {
      isCancelled = true;
    };
  }, [currentPage, props.records]);

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="mt-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-2 shadow-retro border-gray-900 sm:rounded-none">
            {records.length === 0 ? (
              <div className="text-sm font-medium text-gray-900 p-2 bg-white">
                {props.noRecordsMessage}
              </div>
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {props.columns.map((column) => (
                      <th
                        key={Math.random().toString()}
                        scope="col"
                        className={
                          column.propertyName === "action"
                            ? "relative px-6 py-3"
                            : "pl-3 pr-4 py-3 text-left text-sm font-medium text-gray-900 tracking-wider"
                        }
                      >
                        {column.propertyName === "action" ? (
                          <span className="sr-only">Edit</span>
                        ) : (
                          column.displayName
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {records.map((item) => (
                    <tr key={Math.random().toString()}>
                      {props.columns.map((column) => (
                        <td
                          key={Math.random().toString()}
                          className={
                            (column.propertyName === "action"
                              ? "text-right "
                              : "") +
                            "text-left pl-3 pr-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                          }
                        >
                          {item[column.propertyName]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <TablePagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maxPage={maxPage}
          />
        </div>
      </div>
    </div>
  );
}
