export function SetUnicuteId(id) {
    if(parseInt(id) < 10) {
        return "000" + id.toString();
    }
    if(parseInt(id) < 100) {
        return "00" + id.toString();
    }
    if(parseInt(id) < 1000) {
        return "0" + id.toString();
    }
    return id.toString();
}