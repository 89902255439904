import {
  GetSmartContractSubStateWithoutAddr,
  UnicutesMarketplaceContract,
  WorldOfUnicutesContract,
} from "../../../utils/ZilliqaUtils";

export function GetTokenUris() {
  return new Promise((resolve, reject) => {
    let tokenUris = [];
    GetSmartContractSubStateWithoutAddr(
      WorldOfUnicutesContract(),
      "token_uris"
    ).then((response) => {
      if (response === null) {
        return resolve(tokenUris);
      }

      for (const [key, value] of Object.entries(response)) {
        var tokenOwner = {
          id: key,
          uri: value.replace("https://ipfs.io/ipfs/", "")
        };
        tokenUris.push(tokenOwner);
      }
      return resolve(tokenUris);
    });
  });
}