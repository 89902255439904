/* This example requires Tailwind CSS v2.0+ */
import { useContext } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import WalletContext from "../contexts/WalletContext";
import { NavLink } from "react-router-dom";
import { PrimaryButtonStyle } from "../../components/button/ButtonStyle";
import { useLocation } from "react-router-dom";
export default function Navbar() {
  const { setModalOpen, isConnected, setAccountModalOpen, account, balance } =
    useContext(WalletContext);
  const location = useLocation();

  function openWalletModal() {
    if (!isConnected) {
      setModalOpen(true);
      return;
    } else {
      setAccountModalOpen(true);
      setModalOpen(false);
      return;
    }
  }

  function logoColors() {
    return "bg-clip-text bg-gradient-to-r from-unicutePurple-1000 to-unicutePink-400 text-transparent";
  }

  function activeLinkMobile() {
    return "bg-unicutePurple-400 border-unicuteGreen-100 text-gray-900 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6";
  }

  function nonActiveLinkMobile() {
    return "text-gray-900 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6";
  }

  function activeLink() {
    return "border-gray-700 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium";
  }

  function nonActiveLink() {
    return "border-transparent text-gray-900 hover:border-gray-300 hover:text-gray-500 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium";
  }

  function walletButtonText() {
    if (!isConnected) {
      return (
        <>
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          <span>Connect to a wallet</span>
        </>
      );
    } else {
      var accountAddress = account.bech32;
      var address =
        accountAddress.substring(0, 8) +
        "..." +
        accountAddress.slice(accountAddress.length - 4);
      return (
        <div>
          <span>
            {parseFloat(balance).toFixed(2)} ZIL - {address}
          </span>
        </div>
      );
    }
  }

  return (
    <Disclosure as="nav" className="bg-gray-50 shadow">
      {({ open, close }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-none text-gray-700 hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-unicutePurple-400">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <NavLink
                    to="/"
                    exact
                    className={
                      "block lg:hidden font-bold text-3xl pb-2 text-unicutePink-600"
                    }
                  >
                    Unicutes
                  </NavLink>
                  <NavLink
                    to="/"
                    exact
                    className={
                      "hidden lg:block font-bold text-3xl pb-2 text-unicutePink-600"
                    }
                  >
                    Unicutes
                  </NavLink>
                </div>
                <div className="hidden md:ml-6 lg:flex md:space-x-8">
                  <NavLink
                    to="/"
                    exact
                    className={nonActiveLink()}
                    activeClassName={activeLink()}
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/my-unicutes/eggs"
                    exact
                    className={nonActiveLink()}
                    activeClassName={activeLink()}
                  >
                    My unicutes
                  </NavLink>
                  <NavLink
                    to="/explore"
                    className={nonActiveLink()}
                    activeClassName={location.pathname.startsWith("/explore") ? activeLink() : null
                    }
                  >
                    Explore
                  </NavLink>
                  <NavLink
                    to="/traits"
                    exact
                    className={nonActiveLink()}
                    activeClassName={activeLink()}
                  >
                    Traits
                  </NavLink>
                  <NavLink
                    to="/voting-contest"
                    exact
                    className={nonActiveLink()}
                    activeClassName={activeLink()}
                  >
                    Voting contest
                  </NavLink>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <button
                    onClick={() => openWalletModal()}
                    type="button"
                    className={PrimaryButtonStyle("text-sm font-medium")}
                  >
                    {walletButtonText()}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="pt-2 pb-3 space-y-1 text-left">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <NavLink
                to="/"
                exact
                className={nonActiveLinkMobile()}
                activeClassName={activeLinkMobile()}
                onClick={() => {
                  close();
                }}
              >
                Home
              </NavLink>
              <NavLink
                to="/my-unicutes/0"
                exact
                className={nonActiveLinkMobile()}
                activeClassName={activeLinkMobile()}
                onClick={() => {
                  close();
                }}
              >
                My unicutes
              </NavLink>
              <NavLink
                to="/explore/unicutes?page=1"
                exact
                className={nonActiveLinkMobile()}
                activeClassName={activeLinkMobile()}
                onClick={() => {
                  close();
                }}
              >
                Explore
              </NavLink>
              <NavLink
                to="/traits"
                exact
                className={nonActiveLinkMobile()}
                activeClassName={activeLinkMobile()}
                onClick={() => {
                  close();
                }}
              >
                Traits
              </NavLink>
              <NavLink
                to="/voting-contest"
                exact
                className={nonActiveLinkMobile()}
                activeClassName={activeLinkMobile()}
                onClick={() => {
                  close();
                }}
              >
                Voting contest
              </NavLink>
            </div>
            <div
              className={
                "pb-2 border-b-2 border-gray-700 text-md font-bold " +
                logoColors()
              }
            >
              <a
                className=""
                rel="noreferrer"
                href="https://twitter.com/unicutes"
                target="_blank"
              >
                <ExternalLinkIcon
                  className="inline -mt-1 mr-2 h-6 w-6"
                  aria-hidden="true"
                />
                <div className="inline">Follow us on twitter</div>
              </a>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
