import {
  GetSmartContractSubStateWithoutAddr,
  UnicutesContract,
} from "../../../utils/ZilliqaUtils";

export default function GetTokenApprovals(tokenId) {
  return new Promise((resolve, reject) => {
    if (tokenId === null || tokenId === undefined) {
      return resolve(null);
    }
    GetSmartContractSubStateWithoutAddr(UnicutesContract(), "token_approvals", [
      tokenId,
    ]).then((response) => {
      if (response === null || response === undefined) {
        return resolve(null);
      }

      let spender = response[tokenId];
      if (spender !== null) {
        return resolve(spender.toLowerCase());
      }

      return resolve(null);
    });
  });
}
