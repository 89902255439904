import { useContext, useEffect, useRef, useState } from "react";
import { PrimaryButtonStyle } from "../../components/button/ButtonStyle";
import WalletContext from "../../components/contexts/WalletContext";
import {
  eventLogGeneralSubscription,
  GetSmartContractSubState,
  GetSmartContractSubStateWithoutAddr,
  VotingContestOneContract,
} from "../../utils/ZilliqaUtils";
import { VoteOnUnicuteContestOne } from "../../utils/ZilPayUtils";
import VoteOnBehalfOfUnicuteSelect from "./VoteOnBehalfOfUnicuteSelect";
import VoteOnUnicuteSelect from "./VoteOnUnicuteSelect";
import Emoji from "react-emoji-render";

export default function Vote() {
  const { isConnected, ownedUnicutes, account } = useContext(WalletContext);
  const [vote, setVote] = useState(null);
  const [voteOnBehalfOfIndex, setVoteOnBehalfOfIndex] = useState(0);
  const [voteOnBehalfOf, setVoteOnBehalfOf] = useState(null);
  const [votes, setVotes] = useState(null);
  const [trigger, setTrigger] = useState(Math.random());
  const [addrVotes, setAddrVotes] = useState(null);
  const addrVotesRef = useRef(addrVotes);

  useEffect(() => {
    addrVotesRef.current = addrVotes;
  });

  useEffect(() => {
    eventLogGeneralSubscription(
      VotingContestOneContract(),
      "VoteSuccess",
      setTrigger
    );

    return () => {};
  }, []);

  useEffect(() => {
    if (account === null) {
      return;
    }
    GetSmartContractSubState(
      VotingContestOneContract(),
      "addr_unicute_vote",
      account
    ).then((res) => {
      setAddrVotes(res);
    });
    return () => {};
  }, [account, trigger]);
  useEffect(() => {
    let isCancelled = false;
    GetSmartContractSubStateWithoutAddr(
      VotingContestOneContract(),
      "unicute_votes"
    ).then((res) => {
      if (isCancelled) {
        return;
      }
      if (res === null) {
        return;
      }
      var arr = [];
      for (const [key, value] of Object.entries(res)) {
        var newUnicute = {
          id: key,
          numberOfVotes: value.toString(),
        };
        arr.push(newUnicute);
      }
      arr.sort((a, b) => parseInt(b.numberOfVotes) - parseInt(a.numberOfVotes));
      setVotes(arr);
    });
    return () => {
      isCancelled = true;
    };
  }, [trigger]);
  return (
    <>
      <div className="pt-8 pb-12">
        <div className="flex flex-col text-left">
          <div className="font-medium text-gray-900 pb-8">
            <div className="text-3xl">
              <Emoji text="3 lucky unicutes will win a rare NFT artwork containing their unicute 🤩" />
            </div>
            <div className="text-1xl pb-4">
              <Emoji text="The top 2 🥇🥈 most liked unicutes and 1 randomly selected voter wins. Winners will be selected on 19 September, Sunday at 21:00 UTC +2 🔥" />
            </div>
            <div className="text-sm">
              <div>
                <Emoji text="Rules 📜" />
              </div>
              <div>
                1. Only unicute holders can participate in this contest.
              </div>
              <div>
                2. Vote on your favorite unicute, be honest, it might pay off in
                the end. Top 2 will receive a rare NFT artwork of their unicute.
              </div>
              <div>
                3. Each unicute you own can vote once. You're allowed to vote on
                the same unicute more than once.
              </div>
              <div>4. A unicute can't vote on itself.</div>
              <div>
                5. By voting you will have a chance to win a rare NFT artwork of
                the unicute that voted.
              </div>
              <div>
                6. The rare unicute NFT artworks will be airdropped after
                completion. It might take some time, so be patient :)
              </div>
            </div>
            {isConnected ? (
              <>
                <div className="pt-4">
                  <VoteOnBehalfOfUnicuteSelect
                    voteOnBehalfOfIndex={voteOnBehalfOfIndex}
                    setVoteOnBehalfOfIndex={setVoteOnBehalfOfIndex}
                    addrVotes={addrVotesRef}
                    setSelected={setVoteOnBehalfOf}
                    selected={voteOnBehalfOf}
                  />
                  {ownedUnicutes !== null  && ownedUnicutes !== undefined && Object.keys(ownedUnicutes).length !== 0 ? (
                    <div>
                      <div className="text-sm pt-4">
                        Write the id of your favorite unicute
                      </div>
                      <div className="">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            // if value is not blank, then test the regex
                            if (e.target.value === "") {
                              setVote(e.target.value);
                            }
                            if (re.test(e.target.value)) {
                              if (parseInt(e.target.value) > 10000) {
                                return;
                              }
                              setVote(e.target.value);
                            }
                          }}
                          className="shadow-retro border-2 text-gray-900 text-sm font-medium border-gray-900 focus:ring-gray-900 focus:border-gray-900 block w-full rounded-none"
                          placeholder="Insert id of unicute e.g 1"
                        />
                      </div>
                      <button
                        onClick={() => {
                          if (
                            vote === null ||
                            vote === undefined ||
                            vote === ""
                          ) {
                            return;
                          }
                          VoteOnUnicuteContestOne(
                            voteOnBehalfOf.id.toString(),
                            vote.toString()
                          );
                        }}
                        className={PrimaryButtonStyle("mt-4")}
                      >
                        <Emoji className="top-4" text="Vote now 🗳️" />
                      </button>{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div className="text-red-600 font-bold mt-4 text-3xl">
                To vote please connect your wallet
              </div>
            )}
          </div>
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 rounded-none shadow-retro border-2 border-gray-900">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-bold text-gray-900 tracking-wider"
                      >
                        Rank
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-bold text-gray-900 tracking-wider"
                      >
                        Unicute #
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-bold text-gray-900 tracking-wider"
                      >
                        Total number of votes
                      </th>

                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">
                          <Emoji text="See 🦄" />
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {votes !== null &&
                      votes.map((item, itemIdx) => (
                        <tr
                          key={item.id}
                          className={
                            itemIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {itemIdx + 1}{" "}
                            {itemIdx === 0 ? (
                              <Emoji text="🥇" />
                            ) : itemIdx === 1 ? (
                              "🥈"
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {item.id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {item.numberOfVotes}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <a
                              href={"/explore/" + item.id.toString()}
                              target="_blank"
                              className="text-gray-900 hover:text-unicutePurple-900"
                            >
                              <Emoji text="See unicute 🦄" />
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
