import {
  GetSmartContractSubStateWithoutAddr,
  UnicutesMarketplaceContract,
} from "../../../utils/ZilliqaUtils";

function SortTokenHistory(tokenHistory) {
  return tokenHistory.sort((a, b) => b.blockHeight - a.blockHeight);
}

export const unicutesMarketplaceContract =
  UnicutesMarketplaceContract().toLocaleLowerCase();
export const bidOpen = unicutesMarketplaceContract + ".BidOpen";
export const bidWithdrawn = unicutesMarketplaceContract + ".BidWithdrawn";
export const bidAccepted = unicutesMarketplaceContract + ".BidAccepted";

export function GetTokenHistory(tokenId) {
  return new Promise((resolve, reject) => {
    if (tokenId === null || tokenId === undefined) {
      return resolve([]);
    }
    GetSmartContractSubStateWithoutAddr(
      UnicutesMarketplaceContract(),
      "token_history",
      [tokenId]
    ).then((res) => {
      let items = [];
      if (res === null) {
        return resolve(items);
      }
      let tokenHistoryResponse = res[tokenId];
      if (tokenHistoryResponse === null || tokenHistoryResponse === undefined) {
        return resolve(items);
      }
      for (const [key, value] of Object.entries(tokenHistoryResponse)) {
        for (const [innerKey, innerValue] of Object.entries(value)) {
          var tokenHistoryId = innerKey;
          var item = innerValue;
          var newTokenHistoryItem = {
            id: tokenId,
            blockHeight: key,
            amount: item.arguments[0],
            address: item.arguments[1],
            state: item.arguments[2].constructor
          };
          items.push(newTokenHistoryItem);
        }
      }
      return resolve(SortTokenHistory(items));
    });
  });
}
