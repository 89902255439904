import {
  GetSmartcontractState,
  GetSmartContractSubStateWithoutAddr,
  GhostUnicutesContract,
} from "../../../utils/ZilliqaUtils";

export function GetGhostUnicutesMintedNumber() {
  return new Promise((resolve, reject) => {
    GetSmartContractSubStateWithoutAddr(
      GhostUnicutesContract(),
      "token_id_count"
    ).then((response) => {
      return resolve(response);
    });
  });
}

export function GetGhostUnicuteOwnerTokens(enrichedTokens, account) {
  let owner = account.base16.toLowerCase();
  let ownedTokens = [];
  enrichedTokens.forEach((elem) => {
    if (elem.address === owner) {
      ownedTokens.push(elem);
    }
  });
  return ownedTokens;
}

export function GetGhostUnicuteEnrichedTokens(tokenDict, tokenUriDict) {
  let list = [];
  for (const [tokenId, address] of Object.entries(tokenDict)) {
    let tokenUri = tokenUriDict[tokenId];
    let obj = {
      id: tokenId,
      uri: tokenUri.toString().replace("https://ipfs.io/ipfs/", ""),
      address: address,
    };
    list.push(obj);
  }
  return list;
}

export function GetGhostUnicuteTokenUris() {
  return new Promise((resolve, reject) => {
    GetSmartContractSubStateWithoutAddr(
      GhostUnicutesContract(),
      "token_uris"
    ).then((response) => {
      return resolve(response);
    });
  });
}

export function GetGhostUnicuteTokens() {
  return new Promise((resolve, reject) => {
    GetSmartContractSubStateWithoutAddr(
      GhostUnicutesContract(),
      "token_owners"
    ).then((response) => {
      return resolve(response);
    });
  });
}
