import { useContext, useEffect, useState } from "react";
import UnicuteDetailsContext from "../../../../components/contexts/UnicuteDetailsContext";
import WalletContext from "../../../../components/contexts/WalletContext";
import {
  CdnImageLocation,
  GetSmartContractSubStateWithoutAddr,
  UnicutesContract,
} from "../../../../utils/ZilliqaUtils.js";
import axios from "axios";
import { ErrorNotification } from "../../../../components/notification/Notification";
import {
  CalculateRarity,
  GetRarityObject,
} from "../../../../utils/RarityUtils";
import {
  PrimaryButtonStyle,
  SecondaryButtonStyle,
} from "../../../../components/button/ButtonStyle";
import UnicuteRandomValuesModal from "./UnicuteRandomValuesModal";
import "./UnicuteDetailsPageAvatarStyle.css";
import { SetUnicuteId } from "../../../../utils/UnicuteUtils";
import Table from "../../../../components/table/Table";
import Tab, { tabEquals } from "../../../../components/tab/Tab";
import PlaceBidModal from "./PlaceBidModal";
import ActiveBidsTable from "../../../../components/bidsTable/ActiveBidsTable";
import HistoricBidsTable from "../../../../components/bidsTable/HistoricBidsTable";

export default function UnicuteDetailsPage() {
  const { showUnicuteWithId, account, isConnected, setTriggerBids } =
    useContext(WalletContext);
  const [unicute, setUnicute] = useState(null);
  const [unicuteDetails, setUnicuteDetails] = useState(null);
  const [unicuteOwner, setUnicuteOwner] = useState(null);
  const [showRandomValues, setShowRandomValues] = useState(false);
  const [isUnicuteOwner, setIsUnicuteOwner] = useState(false);
  const [showPlaceBidModal, setShowPlaceBidModal] = useState(false);
  const [numberOfActiveBids, setNumberOfActiveBids] = useState(0);
  var unicuteDetailsContextValue = {
    showRandomValues,
    unicute,
    unicuteDetails,
    setShowRandomValues,
    showPlaceBidModal,
    setShowPlaceBidModal,
  };

  const tabs = [
    {
      name: "Active bids",
      href: "/explore/unicutes/" + showUnicuteWithId?.toString() + "/active-bids",
    },
    {
      name: "Historic bids",
      href: "/explore/unicutes/" + showUnicuteWithId?.toString() + "/historic-bids",
    },
    {
      name: "Image hosting (IPFS & Digital Ocean)",
      href: "/explore/unicutes/" + showUnicuteWithId?.toString() + "/images",
    },
  ];
  const [tabName, setTabName] = useState(tabs[0].name);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (showUnicuteWithId === null || showUnicuteWithId === undefined) {
        return;
      }

      if ((account?.base16 ?? null) !== null) {
        setIsUnicuteOwner(account.base16.toLowerCase() === unicuteOwner);
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [unicuteOwner, account, showUnicuteWithId]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (showUnicuteWithId === null || showUnicuteWithId === undefined) {
        return;
      }

      axios
        .get(
          "https://backend.unicutes.app/api/Unicute/GetUnicuteDetails?id=" +
            showUnicuteWithId.toString()
        )
        .then((response) => {
          setUnicuteDetails(response.data);
        })
        .catch((error) =>
          ErrorNotification(
            "Failed to get details of unicute",
            "The webserver returned: " + error
          )
        );
      // Find token owner
      GetSmartContractSubStateWithoutAddr(UnicutesContract(), "token_owners", [
        showUnicuteWithId.toString(),
      ]).then((result) => {
        if (result === null) {
          return;
        }
        var ownerAddr = Object.values(result)[0];
        setUnicuteOwner(ownerAddr);
        GetSmartContractSubStateWithoutAddr(
          UnicutesContract(),
          "owned_tokens",
          [ownerAddr, showUnicuteWithId.toString()]
        ).then((ownedUnicuteRes) => {
          var ownedUnicute = Object.values(ownedUnicuteRes)[0];
          var ownedUnicuteArgs = Object.values(ownedUnicute)[0];
          setUnicute({
            drandRound: ownedUnicuteArgs.arguments[0],
            drandIndex: ownedUnicuteArgs.arguments[1],
            drandValue: ownedUnicuteArgs.arguments[2]?.arguments[0],
            isLegendary:
              ownedUnicuteArgs.arguments[3]?.arguments[0]?.constructor,
          });
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [showUnicuteWithId]);

  return (
    <UnicuteDetailsContext.Provider value={unicuteDetailsContextValue}>
      <UnicuteRandomValuesModal />
      <PlaceBidModal tokenOwner={unicuteOwner} unicuteId={showUnicuteWithId} />

      <div className="text-left shadow-common mt-4 mb-16 border-2 border-gray-900">
        {unicuteDetails === null ? (
          <div className="m-2 text-gray-900 font-medium">
            Loading details of unicute #{showUnicuteWithId}...
          </div>
        ) : (
          <>
            <div className="h-64 bg-unicuteGreen-200 bg-opacity-20 text-center relative">
              <img
                alt=""
                className="h-64 object-contain inline transform scale-125"
                src={
                  unicuteDetails.isImageCreated
                    ? CdnImageLocation() +
                      "-output/" +
                      showUnicuteWithId +
                      ".png"
                    : "/00.png"
                }
              />
              <div className="absolute bottom-0 right-0">
                <div className="pr-4 pb-4">
                  {!isUnicuteOwner && isConnected ? (
                    <button
                      onClick={() => setShowPlaceBidModal(true)}
                      className={PrimaryButtonStyle("")}
                    >
                      Place bid
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="overflow-hidden rounded-none">
              <div className="px-4 py-5 sm:px-6">
                <div className="">
                  <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-4">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <div className="box relative">
                            <span className=""></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <div className="content border-gray-900 border-4 z-20">
                              <img
                                className="p-2 transform scale-150 translate-x-0 -translate-y-2 max-h-20 z-10"
                                src={
                                  unicuteDetails.isImageCreated
                                    ? CdnImageLocation() +
                                      "-output-avatar/" +
                                      showUnicuteWithId +
                                      "-avatar.png"
                                    : "/00.png"
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h3 className="text-lg leading-6 font-bold text-gray-900">
                            #{SetUnicuteId(showUnicuteWithId)}
                          </h3>
                          <p className="text-xs font-medium text-gray-900">
                            Owned by {unicuteOwner}
                          </p>
                          <p className="text-xs font-medium text-gray-900">
                            Is full set:{" "}
                            {unicuteDetails.isLegendaryHexValue === null
                              ? "Unknown"
                              : unicuteDetails.isLegendary
                                  .toString()
                                  .toLowerCase()}{" "}
                            (0x
                            {unicuteDetails?.isLegendaryHexValue})
                          </p>
                          {unicuteDetails.isLegendary ? (
                            <p className="text-xs font-medium text-gray-900">
                              Full set: 0x
                              {unicuteDetails?.legendarySetHexValue}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0 flex">
                      <button
                        onClick={() => setShowRandomValues(true)}
                        className={SecondaryButtonStyle("")}
                      >
                        Show random values for unicute #{showUnicuteWithId}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-3">
                  {unicuteDetails?.partItems !== null &&
                    unicuteDetails?.partItems !== undefined &&
                    unicuteDetails.partItems.map((part, index) => {
                      var calculatedRarity = CalculateRarity(
                        part.totalNumberOfTypesFound,
                        part.numberOfTimesFound
                      );
                      var rarity = GetRarityObject(calculatedRarity);
                      return (
                        <div
                          key={part.id.toString() + "-" + index}
                          className="sm:col-span-1"
                        >
                          <dt
                            style={{ color: rarity.colorCode }}
                            className="text-sm font-medium"
                          >
                            {rarity.name} {part.type.toLowerCase()} #
                            {part.id.toString()} ({calculatedRarity}%)
                          </dt>
                          <dd className="mt-1 text-xs font-medium text-gray-500">
                            Values: {part.randomValues.toString()}
                          </dd>
                        </div>
                      );
                    })}
                  <div className="sm:col-span-3">
                    <Tab
                      className="mt-4"
                      items={tabs}
                      currentTab={tabName}
                      setTabName={setTabName}
                    />
                  </div>
                  {tabEquals(tabName, tabs[0].name) && showUnicuteWithId !== null ? (
                    <div className="sm:col-span-3">
                      <ActiveBidsTable unicuteId={showUnicuteWithId} />
                    </div>
                  ) : (
                    ""
                  )}
                  {tabEquals(tabName, tabs[1].name) && showUnicuteWithId !== null ? (
                    <div className="sm:col-span-3">
                      <HistoricBidsTable unicuteId={showUnicuteWithId} />
                    </div>
                  ) : (
                    ""
                  )}
                  {tabEquals(tabName, tabs[2].name) && showUnicuteWithId !== null ? (
                    <div className="sm:col-span-3">
                      <dd className="mt-1 text-sm text-gray-900 bg-white">
                        <ul className="border-2 border-gray-900 rounded-none divide-y divide-gray-200 shadow-retro">
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <svg
                                className="flex-shrink-0 h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 0L1.608 6v12L12 24l10.392-6V6zm-1.073 1.445h.001a1.8 1.8 0 0 0 2.138 0l7.534 4.35a1.794 1.794 0 0 0 0 .403l-7.535 4.35a1.8 1.8 0 0 0-2.137 0l-7.536-4.35a1.795 1.795 0 0 0 0-.402zM21.324 7.4c.109.08.226.147.349.201v8.7a1.8 1.8 0 0 0-1.069 1.852l-7.535 4.35a1.8 1.8 0 0 0-.349-.2l-.009-8.653a1.8 1.8 0 0 0 1.07-1.851zm-18.648.048l7.535 4.35a1.8 1.8 0 0 0 1.069 1.852v8.7c-.124.054-.24.122-.349.202l-7.535-4.35a1.8 1.8 0 0 0-1.069-1.852v-8.7a1.85 1.85 0 0 0 .35-.202z" />
                              </svg>

                              <span className="ml-2 flex-1 w-0 truncate font-medium">
                                Unicute character
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              {unicuteDetails?.ipfsHash !== null ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={
                                    "https://ipfs.io/ipfs/" +
                                    unicuteDetails.ipfsHash?.toString()
                                  }
                                  className="font-medium text-gray-900 hover:text-gray-500"
                                >
                                  View on IPFS
                                </a>
                              ) : (
                                <p className="font-medium text-gray-900">
                                  Generating
                                </p>
                              )}
                            </div>
                          </li>

                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <svg
                                className="flex-shrink-0 h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="65.2 173.5 32 32"
                                width="64"
                                height="64"
                                fill="#0080ff"
                              >
                                <path d="M81.202 205.5v-6.2c6.568 0 11.666-6.5 9.144-13.418a9.27 9.27 0 0 0-5.533-5.531c-6.912-2.502-13.425 2.575-13.425 9.14H65.2c0-10.463 10.124-18.622 21.1-15.195 4.8 1.505 8.618 5.313 10.105 10.1 3.43 10.99-4.717 21.107-15.203 21.107z" />
                                <path d="M75.05 199.317v-6.165h6.168v6.165zm-4.753 4.75v-4.75h4.753v4.75h-4.753zm0-4.75h-3.973v-3.97h3.973v3.97z" />
                              </svg>
                              <span className="ml-2 flex-1 w-0 truncate font-medium">
                                Unicute character
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              {unicuteDetails?.ipfsHash !== null ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={
                                    CdnImageLocation() +
                                    "-output/" +
                                    unicuteDetails.smartContractId +
                                    ".png"
                                  }
                                  className="font-medium text-gray-900 hover:text-gray-500"
                                >
                                  View on DigitalOcean
                                </a>
                              ) : (
                                <p className="font-medium text-gray-900">
                                  Generating
                                </p>
                              )}
                            </div>
                          </li>
                        </ul>
                      </dd>
                    </div>
                  ) : (
                    ""
                  )}

                  <div></div>
                </dl>
              </div>
            </div>
          </>
        )}
      </div>
    </UnicuteDetailsContext.Provider>
  );
}
