/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import WalletContext from "../../components/contexts/WalletContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function VoteOnBehalfOfUnicuteSelect(props) {
  const { ownedUnicutes } = useContext(WalletContext);
  const [items, setItems] = useState(null);

  useEffect(() => {
    const unicutes = [];
    if (ownedUnicutes === null) {
      return;
    }
    for (const [key, value] of Object.entries(ownedUnicutes)) {
      let notVoted = props.addrVotes.current === null ? true : !(key in props.addrVotes.current);
      var newUnicute = {
        id: key,
        isUsable: notVoted,
        votedOn: notVoted ? null : (props.addrVotes.current[key]).toString()
      };
      unicutes.push(newUnicute);
    }
    setItems(unicutes);
    props.setSelected(unicutes[props.voteOnBehalfOfIndex]);
    return () => {
    };
  }, [ownedUnicutes, props.addrVotes.current]);

  useEffect(() => {
    return () => {};
  }, [props.selected]);

  return (
    <>
      {props.selected === undefined ||
      props.selected === null ||
      items === null ||
      items === undefined ? (
        "You don't own any unicutes :("
      ) : (
        <Listbox value={props.selected} onChange={props.setSelected}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium text-gray-900">
                Vote on behalf of unicute
              </Listbox.Label>
              <div className="mt-1 relative">
                <Listbox.Button className="relative w-full bg-white border-2 shadow-retro border-gray-900 rounded-none shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
                  <div className="flex items-center">
                    <span
                      aria-label={
                        props.selected.isUsable ? "Online" : "Offline"
                      }
                      className={classNames(
                        props.selected.isUsable
                          ? "bg-green-400"
                          : "bg-gray-200",
                        "flex-shrink-0 inline-block h-2 w-2 rounded-full"
                      )}
                    />
                    <span className="ml-3 block truncate font-medium">
                      Unicute #{props.selected.id} {props.selected.isUsable ? "" : "(voted on #" + props.selected.votedOn + ")"}
                    </span>
                  </div>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-none py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {items.map((unicute, index) => (
                      <Listbox.Option
                        key={unicute.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-unicutePurple-500"
                              : "text-gray-900",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={unicute}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center" onClick={() => {props.setVoteOnBehalfOfIndex(index); }}>
                              <span
                                className={classNames(
                                  unicute.isUsable
                                    ? "bg-green-400"
                                    : "bg-gray-200",
                                  "flex-shrink-0 inline-block h-2 w-2 rounded-full"
                                )}
                                aria-hidden="true"
                              />
                              <span
                                className={classNames(
                                  "ml-3 block truncate font-medium"
                                )}
                              >
                                Unicute #{unicute.id} {unicute.isUsable ? "" : "(voted on #" + unicute.votedOn + ")"}
                                <span className="sr-only">
                                  {" "}
                                  is {unicute.isUsable ? "online" : "offline"}
                                </span>
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-unicutePurple-500",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      )}
    </>
  );
}
