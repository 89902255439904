/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import MyUnicutesContext from "../../../components/contexts/MyUnicutesContext";
import {
  PrimaryButtonStyle,
  SecondaryButtonStyle,
  InactivePrimaryButtonStyle,
  InactiveSecondaryButtonStyle,
} from "../../../components/button/ButtonStyle";
import {
  InteractWithContractHatchEgg,
  InteractWithContractPaidHatch,
} from "../../../utils/ZilPayUtils";
import { ModalStyle } from "../../../components/modal/ModalStyle";
import WalletContext from "../../../components/contexts/WalletContext";
import { getBlocksLeft, QaToZil } from "../../../utils/ZilliqaUtils.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EggDetailsModal() {
  const [isHatchable, setIsHatchable] = useState(false);
  const {
    modalEggDetailsOpen,
    setModalEggDetailsOpen,
    modalEggDetailsData,
    setLockedEggs,
    lockedEggsRef,
  } = useContext(MyUnicutesContext);
  const { blockNumber, eggHatchPrice } = useContext(WalletContext);

  useEffect(() => {
    let isCancelled = false;
    if (modalEggDetailsData === null) {
      if (!isCancelled) {
        setIsHatchable(false);
      }
      return;
    }
    var blocksLeft = getBlocksLeft(
      modalEggDetailsData.hatchableAt,
      blockNumber
    );

    if (blocksLeft === 0) {
      if (!isCancelled && modalEggDetailsData.state === "1") {
        setIsHatchable(true);
      } else {
        if (!isCancelled) {
          setIsHatchable(false);
        }
      }
    } else {
      if (!isCancelled) {
        setIsHatchable(false);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [modalEggDetailsData, blockNumber]);

  if (modalEggDetailsData == null) {
    return <></>;
  }

  return (
    <Transition.Root show={modalEggDetailsOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setModalEggDetailsOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={ModalStyle()}>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 p-6">
                <button
                  onClick={() => setModalEggDetailsOpen(false)}
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-indigo-500"
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Egg #{modalEggDetailsData.id}
                </h2>
                <p className="mt-1 max-w-2xl text-xs font-medium text-gray-500">
                  State:{" "}
                  {isHatchable
                    ? "Hatch for free"
                    : modalEggDetailsData.state === "1"
                    ? "Wait or pay to hatch now"
                    : "Hatched"}
                </p>
              </div>
              <div className="grid grid-cols-12 text-center text-gray-600 gap-4 mb-4 px-4 sm:px-6">
                <div className="col-span-12 text-left text-xs font-medium">
                  <div className="grid grid-cols-12">
                    <div className="col-span-8 text-gray-500">
                      Created at block
                    </div>
                    <div className="col-span-4 mb-1">
                      {modalEggDetailsData.createdAt}
                    </div>
                    <div className="col-span-8 text-gray-500">
                      Hatchable at block
                    </div>
                    <div className="col-span-4 mb-1">
                      {modalEggDetailsData.hatchableAt}
                    </div>
                    <div className="col-span-8 text-gray-500">
                      Current block
                    </div>
                    <div className="col-span-4 mb-1">{blockNumber}</div>
                    <div className="col-span-8 text-gray-500">
                      Remaining blocks
                    </div>
                    <div className="col-span-4 mb-1">
                      {getBlocksLeft(
                        modalEggDetailsData.hatchableAt,
                        blockNumber
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-span-12">
                  <button
                    onClick={() =>
                      InteractWithContractPaidHatch(
                        modalEggDetailsData.id,
                        setModalEggDetailsOpen,
                        setLockedEggs,
                        lockedEggsRef,
                        eggHatchPrice
                      )
                    }
                    disabled={!(!isHatchable && modalEggDetailsData.state === "1")}
                    className={classNames(
                      !isHatchable && modalEggDetailsData.state === "1"
                        ? PrimaryButtonStyle()
                        : InactivePrimaryButtonStyle(),
                      "text-sm font-medium w-full mb-3"
                    )}
                  >
                    Hatch / Exeggcute (requires {QaToZil(eggHatchPrice)} ZIL)
                  </button>
                  <button
                    onClick={() =>
                      InteractWithContractHatchEgg(
                        modalEggDetailsData.id,
                        setModalEggDetailsOpen,
                        setLockedEggs,
                        lockedEggsRef
                      )
                    }
                    disabled={!isHatchable}
                    className={
                      (isHatchable
                        ? PrimaryButtonStyle()
                        : InactivePrimaryButtonStyle()) +
                      " text-sm font-medium w-full"
                    }
                  >
                    Hatch / Exeggcute (in{" "}
                    {getBlocksLeft(
                      modalEggDetailsData.hatchableAt,
                      blockNumber
                    )}{" "}
                    blocks)
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
