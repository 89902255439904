import { toBech32Address } from "@zilliqa-js/crypto";
import { useContext, useEffect, useState } from "react";
import { IsBidPlaced } from "../../data/smartcontract/unicutesMarket/GetBidderTokens";
import {
  bidAccepted,
  bidOpen,
  bidWithdrawn,
  GetTokenHistory,
} from "../../data/smartcontract/unicutesMarket/GetTokenHistory";
import { QaToZil } from "../../utils/ZilliqaUtils";
import {
  InteractWithContractWithdrawBid,
} from "../../utils/ZilPayUtils";
import WaitingAnimation from "../animations/WaitingAnimation";
import {
  InactiveSecondaryButtonStyle,
  SecondaryButtonStyle,
} from "../button/ButtonStyle";
import WalletContext from "../contexts/WalletContext";
import { SuccessNotification } from "../notification/Notification";
import Table from "../table/Table";
import BidModal from "./modals/BidModal";

let columns = [
  {
    displayName: "Bid",
    propertyName: "amount",
  },
  {
    displayName: "Blockheight",
    propertyName: "blockHeight",
  },
  {
    displayName: "State",
    propertyName: "state",
  },
  {
    displayName: "From",
    propertyName: "address",
  },
  {
    displayName: "Action",
    propertyName: "action",
  },
];

let myBidsColumn = [
  {
    displayName: "Token identifier",
    propertyName: "id",
  },{
    displayName: "Bid",
    propertyName: "amount",
  },
  {
    displayName: "Action",
    propertyName: "action",
  },
]

function ToUnicuteIdList(ownedUnicutes) {
  let unicuteIdList = [];
  if (ownedUnicutes === null || ownedUnicutes === undefined) {
    return unicuteIdList;
  }
  for (const [key, value] of Object.entries(ownedUnicutes)) {
    unicuteIdList.push(key);
  }
  return unicuteIdList;
}

function ToState(zilState) {
  if (zilState === bidAccepted) {
    return "Bid accepted";
  } else if (zilState === bidOpen) {
    return "Bid";
  } else if (zilState === bidWithdrawn) {
    return "Bid withdrawn";
  } else {
    return "Unknown";
  }
}

function IsUnicuteOwner(tokenId, ownedUnicutes) {
  return ownedUnicutes.includes(tokenId);
}

function IsBidder(address, account) {
  return (
    account !== null &&
    account !== undefined &&
    address.toLowerCase() === account.base16.toLowerCase()
  );
}

export default function BidsTable(props) {
  const { account, ownedUnicutes, setTriggerBids, triggerBids } =
    useContext(WalletContext);
  const [records, setRecords] = useState([]);
  const [acceptedBidData, setAcceptedBidData] = useState(null);
  const [isWithdrawingConfirming, setIsWithdrawingConfirming] = useState(false);
  const [isWithdrawingTokenBid, setIsWithdrawingTokenBid] = useState(null);
  const [showBidModal, setShowBidModal] = useState(false);

  function WithdrawSuccessCallback(tokenId) {
    setIsWithdrawingTokenBid(tokenId);
    setIsWithdrawingConfirming(true);
  }

  useEffect(() => {
    let isCancelled = false;
    if (!isWithdrawingConfirming) {
      return;
    }

    const interval = setInterval(() => {
      !isCancelled &&
        IsBidPlaced(account, isWithdrawingTokenBid).then((isBidPlaced) => {
          if (!isBidPlaced) {
            !isCancelled && setIsWithdrawingTokenBid(null);
            !isCancelled &&
              SuccessNotification(
                "Bid withdrawn",
                "Transaction to withdraw bid on unicute #" +
                  isWithdrawingTokenBid.toString() +
                  " has been confirmed by the blockchain."
              );
            !isCancelled && setTriggerBids(Math.random());
            !isCancelled && setIsWithdrawingConfirming(false);
          }
        });
    }, 10000);

    return () => {
      isCancelled = true;
      clearInterval(interval);
    };
  }, [isWithdrawingConfirming]);

  useEffect(() => {
    let isCancelled = false;
    let items = [];
    let ownedUnicuteIds = ToUnicuteIdList(ownedUnicutes);
    props.records.forEach((x) => {
      items.push({
        id: x.id,
        amount: QaToZil(x.amount).toString() + " ZIL",
        blockHeight: x.blockHeight,
        state: ToState(x.state),
        address: toBech32Address(x.address),
        isWithdrawable: false,
        isOffer: false,
        action: props.disableAction ? (
          <div className="px-4 py-2">-</div>
        ) : IsUnicuteOwner(x.id, ownedUnicuteIds) ? (
          <button
            onClick={() => {
              setAcceptedBidData({
                tokenId: x.id,
                amount: x.amount,
                address: x.address,
              });
              setShowBidModal(true);
            }}
            className={SecondaryButtonStyle("")}
          >
            Accept bid
          </button>
        ) : IsBidder(x.address, account) ? (
          <button
            onClick={() => {
              if (isWithdrawingConfirming) {
                return;
              }
              InteractWithContractWithdrawBid(x.id, WithdrawSuccessCallback);
            }}
            className={
              isWithdrawingConfirming
                ? InactiveSecondaryButtonStyle("")
                : SecondaryButtonStyle("")
            }
          >
            {isWithdrawingConfirming ? <WaitingAnimation /> : ""}
            {isWithdrawingConfirming ? "Withdrawing" : "Withdraw bid"}
          </button>
        ) : (
          <div className="px-4 py-2">-</div>
        ),
      });
    });
    !isCancelled && setRecords(items);
    return () => {
      isCancelled = true;
    };
  }, [props.records, isWithdrawingConfirming, ownedUnicutes]);
  return (
    <>
      <BidModal
        showBidModal={showBidModal}
        setShowBidModal={setShowBidModal}
        acceptedBidData={acceptedBidData}
        tokenName={"Unicute"}
      />
      <Table
        records={records}
        noRecordsMessage={props.noRecordsMessage}
        columns={props.isMyBids ? myBidsColumn : columns}
      />
    </>
  );
}
