import {
  ErrorNotification,
  PendingNotification,
} from "../components/notification/Notification";
import {
  GhostUnicutesContract,
  ProxyContract,
  ProxyGhostUnicutesContract,
  UnicutesContract,
  UnicutesMarketplaceContract,
  VotingContestOneContract,
} from "./ZilliqaUtils";

export function isZilpayInstalled() {
  return typeof window.zilPay !== "undefined";
}

export function ConnectToWallet(
  setModalOpenFunc
) {
  setModalOpenFunc(false);
}

const ProxyContractEggHatchingFree = 0;
export async function InteractWithContractBuyEgg(amount) {
  const contracts = window.zilPay.contracts;
  const utils = window.zilPay.utils;
  const contract = contracts.at(ProxyContract());
  const gasPrice = utils.units.toQa("1000", utils.units.Units.Li);
  // Sending to DS
  await contract
    .call(
      "BuyEgg",
      [],
      {
        amount,
        gasPrice,
        gasLimit: utils.Long.fromNumber(9000),
      },
      true
    )
    .then((egg) => {
      console.log("succeeded: " + JSON.stringify(egg));
      PendingNotification(
        "Egg purchase is being confirmed",
        'You can see all your eggs under "My unicutes".',
        "See all eggs",
        "/my-unicutes/0"
      );
    })
    .catch((e) => {
      console.log("exception: " + e);
      ErrorNotification("Buy egg", "An error has occurred: " + e);
    });
}

export async function InteractWithContractPaidHatch(
  id,
  setShowModalFunc,
  setLockedEggsFunc,
  lockedEggsRef,
  amount
) {
  const contracts = window.zilPay.contracts;
  const utils = window.zilPay.utils;
  const contract = contracts.at(ProxyContract());
  const gasPrice = utils.units.toQa("1000", utils.units.Units.Li);
  // Sending to DS
  await contract
    .call(
      "PaidHatchEgg",
      [
        {
          vname: "egg_id",
          type: "Uint256",
          value: id.toString(),
        },
      ],
      {
        amount,
        gasPrice,
        gasLimit: utils.Long.fromNumber(9000),
      },
      true
    )
    .then((egg) => {
      lockedEggsRef.current.push(id);
      setLockedEggsFunc(lockedEggsRef.current);
      PendingNotification(
        "Pay to hatch",
        "Your payment to hatch egg #" +
          id.toString() +
          " is being confirmed by the blockchain."
      );
      setShowModalFunc(false);
    })
    .catch((e) => {
      console.log("exception: " + e);
      ErrorNotification("Pay to hatch", "An error has occurred: " + e);
    });
}

export async function InteractWithContractHatchEgg(
  id,
  setShowModalFunc,
  setLockedEggsFunc,
  lockedEggsRef
) {
  const contracts = window.zilPay.contracts;
  const utils = window.zilPay.utils;
  const contract = contracts.at(ProxyContract());
  const amount = utils.units.toQa(
    ProxyContractEggHatchingFree,
    utils.units.Units.Zil
  );
  const gasPrice = utils.units.toQa("1000", utils.units.Units.Li);
  // Sending to DS
  await contract
    .call(
      "HatchEgg",
      [
        {
          vname: "egg_id",
          type: "Uint256",
          value: id.toString(),
        },
      ],
      {
        amount,
        gasPrice,
        gasLimit: utils.Long.fromNumber(9000),
      },
      true
    )
    .then((egg) => {
      lockedEggsRef.current.push(id);
      setLockedEggsFunc(lockedEggsRef.current);
      PendingNotification(
        "Hatch egg",
        "Transaction to hatch egg #" +
          id.toString() +
          " is being confirmed by the blockchain."
      );
      setShowModalFunc(false);
    })
    .catch((e) => {
      console.log("exception: " + e);
      ErrorNotification("Hatch egg", "An error has occurred: " + e);
    });
}

export async function VoteOnUnicuteContestOne(voter, vote) {
  const contracts = window.zilPay.contracts;
  const utils = window.zilPay.utils;
  const contract = contracts.at(VotingContestOneContract());
  const amount = utils.units.toQa(0, utils.units.Units.Zil);
  const gasPrice = utils.units.toQa("1000", utils.units.Units.Li);
  // Sending to DS
  await contract
    .call(
      "VoteOnUnicute",
      [
        {
          vname: "voter",
          type: "Uint256",
          value: voter.toString(),
        },
        {
          vname: "vote",
          type: "Uint256",
          value: vote.toString(),
        },
      ],
      {
        amount,
        gasPrice,
        gasLimit: utils.Long.fromNumber(9000),
      },
      true
    )
    .then((egg) => {
      PendingNotification(
        "Voting",
        "Your vote for unicute #" +
          vote.toString() +
          " is being confirmed by the blockchain."
      );
    })
    .catch((e) => {
      console.log("exception: " + e);
      ErrorNotification("Voting", "An error has occurred: " + e);
    });
}



export async function InteractWithContractAcceptBid(
  token_id,
  bidder,
  ui_bid_amount,
  acceptBidSuccessCallback
) {
  const contracts = window.zilPay.contracts;
  const utils = window.zilPay.utils;
  const contract = contracts.at(UnicutesMarketplaceContract());
  const amount = utils.units.toQa(0, utils.units.Units.Zil);
  const gasPrice = utils.units.toQa("1000", utils.units.Units.Li);
  // Sending to DS
  await contract
    .call(
      "AcceptBid",
      [
        {
          vname: "token_id",
          type: "Uint256",
          value: token_id.toString(),
        },
        {
          vname: "bidder",
          type: "ByStr20",
          value: bidder.toString(),
        },
        {
          vname: "ui_bid_amount",
          type: "Uint128",
          value: ui_bid_amount.toString(),
        },
      ],
      {
        amount,
        gasPrice,
        gasLimit: utils.Long.fromNumber(9000),
      },
      true
    )
    .then((trx) => {
      acceptBidSuccessCallback();
      PendingNotification(
        "Accept bid",
        "Transaction to accept bid on unicute #" +
          token_id.toString() +
          " is being confirmed by the blockchain."
      );
    })
    .catch((e) => {
      console.log("exception: " + e);
      ErrorNotification("Accept bid", "An error has occurred: " + e);
    });
}

export async function InteractWithContractWithdrawBid(
  token_id,
  successCallback
) {
  const contracts = window.zilPay.contracts;
  const utils = window.zilPay.utils;
  const contract = contracts.at(UnicutesMarketplaceContract());
  const amount = utils.units.toQa(0, utils.units.Units.Zil);
  const gasPrice = utils.units.toQa("1000", utils.units.Units.Li);
  // Sending to DS
  await contract
    .call(
      "WithdrawBid",
      [
        {
          vname: "token_id",
          type: "Uint256",
          value: token_id.toString(),
        },
      ],
      {
        amount,
        gasPrice,
        gasLimit: utils.Long.fromNumber(9000),
      },
      true
    )
    .then((trx) => {
      successCallback(token_id);
      PendingNotification(
        "Bid withdrawn",
        "Transaction to withdraw bid on unicute #" +
        token_id.toString() +
          " is being confirmed by the blockchain."
      );
    })
    .catch((e) => {
      console.log("exception: " + e);
      ErrorNotification("Bid withdrawn", "An error has occurred: " + e);
    });
}

export async function InteractWithContractPlaceBid(
  token_id,
  token_owner,
  bid_amount,
  successCallback
) {
  const contracts = window.zilPay.contracts;
  const utils = window.zilPay.utils;
  const contract = contracts.at(UnicutesMarketplaceContract());
  const amount = utils.units.toQa(bid_amount, utils.units.Units.Zil);
  const gasPrice = utils.units.toQa("1000", utils.units.Units.Li);
  // Sending to DS
  await contract
    .call(
      "CreateBid",
      [
        {
          vname: "token_id",
          type: "Uint256",
          value: token_id.toString(),
        },
        {
          vname: "token_owner",
          type: "ByStr20",
          value: token_owner.toString(),
        },
      ],
      {
        amount,
        gasPrice,
        gasLimit: utils.Long.fromNumber(9000),
      },
      true
    )
    .then((trx) => {
      successCallback();
      PendingNotification(
        "Bid placed",
        "Transaction to bid on unicute #" +
        token_id.toString() +
          " is being confirmed by the blockchain."
      );
    })
    .catch((e) => {
      console.log("exception: " + e);
      ErrorNotification("Bid placed", "An error has occurred: " + e);
    });
}

//SetApprove(to: ByStr20, token_id: Uint256)

export async function InteractWithContractSetApprove(
  token_id,
  successCallback,
  to
) {
  const contracts = window.zilPay.contracts;
  const utils = window.zilPay.utils;
  const contract = contracts.at(UnicutesContract());
  const amount = utils.units.toQa(0, utils.units.Units.Zil);
  const gasPrice = utils.units.toQa("1000", utils.units.Units.Li);
  // Sending to DS
  await contract
    .call(
      "SetApprove",
      [
        {
          vname: "token_id",
          type: "Uint256",
          value: token_id.toString(),
        },
        {
          vname: "to",
          type: "ByStr20",
          value: to,
        },
      ],
      {
        amount,
        gasPrice,
        gasLimit: utils.Long.fromNumber(9000),
      },
      true
    )
    .then((trx) => {
      successCallback();
      PendingNotification(
        "Approve transfer",
        "Transaction to approve transfer of unicute #" +
        token_id.toString() +
          " is being confirmed by the blockchain."
      );
    })
    .catch((e) => {
      console.log("exception: " + e);
      ErrorNotification("Approve transfer", "An error has occurred: " + e);
    });
}


export async function InteractWithContractBurn(
  token_id,
  successCallback
) {
  const contracts = window.zilPay.contracts;
  const utils = window.zilPay.utils;
  const contract = contracts.at(ProxyGhostUnicutesContract());
  const amount = utils.units.toQa(0, utils.units.Units.Zil);
  const gasPrice = utils.units.toQa("1000", utils.units.Units.Li);
  // Sending to DS
  await contract
    .call(
      "BurnUnicute",
      [
        {
          vname: "token_id",
          type: "Uint256",
          value: token_id.toString(),
        },
      ],
      {
        amount,
        gasPrice,
        gasLimit: utils.Long.fromNumber(9000),
      },
      true
    )
    .then((trx) => {
      successCallback();
      PendingNotification(
        "Burning unicute",
        "Transaction to burn unicute #" +
        token_id.toString() +
          " is being confirmed by the blockchain."
      );
    })
    .catch((e) => {
      console.log("exception: " + e);
      ErrorNotification("Burning unicute", "An error has occurred: " + e);
    });
}