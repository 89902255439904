import { useEffect, useRef } from "react";

export function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if(delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            }
        }
    }, [callback, delay])
}


export function calculateTotalPages(length, take) {
    return parseInt(length != 0 ? Math.ceil(parseInt(length) / parseInt(take)) : 0);
}