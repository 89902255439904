import { InformationCircleIcon } from "@heroicons/react/outline";
import LazyLoad from "react-lazyload";
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { ErrorNotification } from "../notification/Notification";
import { CdnImageLocation } from "../../utils/ZilliqaUtils.js";
import { CalculateRarity, GetRarityObject } from "../../utils/RarityUtils";
import UnicutesPropertiesContext from "../contexts/UnicutesPropertiesContext";

export default function UnicutePartStatistics(props) {
  const [totalPartsFound, setTotalPartsFound] = useState(null);
  const [parts, setParts] = useState(null);
  const { setShowModal, setImageUrl } = useContext(UnicutesPropertiesContext);
  useEffect(() => {
    // GET request using axios inside useEffect React hook
    axios
      .get(
        "https://backend.unicutes.app/api/Traits/GetParts?type=" + props.name
      )
      .then((response) => {
        setParts(response.data.items);
        setTotalPartsFound(response.data.totalPartsFound);
      })
      .catch((error) =>
        ErrorNotification(
          "Failed to get traits",
          "The webserver return error: " + error
        )
      );
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [props.name]);

  return (
    <>
      <div className="grid grid-cols-12 gap-4 mt-8 mb-8">
        <div className="col-span-12 gap-4">
          <ul className="grid grid-cols-2 gap-x-2 gap-y-8 sm:grid-cols-3 sm:gap-x-2 lg:grid-cols-6 xl:gap-x-4">
            {parts !== null &&
              parts.map((partItem, i) => {
                let rarity = CalculateRarity(
                  totalPartsFound,
                  partItem.totalTimesFound
                );
                var rarityObject = GetRarityObject(rarity);
                var boxShadowStyle = rarityObject.colorCode + " 4px 4px";
                var imageSrc = partItem.isDiscovered
                  ? CdnImageLocation() +
                    "-output-parts/" +
                    props.name +
                    "/" +
                    partItem.id +
                    "-discovered.png"
                  : CdnImageLocation() +
                    "-obfuscated/" +
                    props.name +
                    "/" +
                    partItem.id +
                    ".png";
                return (
                  <LazyLoad
                    key={props.name + "-lazy-" + i}
                    height={50 * (i === 1 ? 0 : i / 6)}
                  >
                    <li
                      key={props.name + "-" + i}
                      className="relative"
                      onClick={() => {
                        setImageUrl(imageSrc);
                        setShowModal(true);
                      }}
                    >
                      <div
                        style={{ boxShadow: boxShadowStyle }}
                        className={
                          "cursor-pointer border-gray-900 group border-2 block w-full aspect-w-10 aspect-h-7 rounded-none bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
                        }
                      >
                        <img
                          src={imageSrc}
                          alt=""
                          className="object-contain inline h-24 transform transition duration-500 hover:scale-125 group-hover:opacity-50 p-4"
                        />

                        <button
                          type="button"
                          className="absolute inset-0 focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for {props.name}
                          </span>
                        </button>
                        <div className="absolute top-0 right-0 p-1">
                          <div className="text-right text-sm">
                            <InformationCircleIcon className="h-5 text-gray-900 group-hover:opacity-50" />
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <p
                          style={{ color: rarityObject.colorCode }}
                          className="mt-2 block text-xs text-left font-bold truncate pointer-events-none"
                        >
                          {rarityObject.name} part #{partItem.id} ({rarity}%)
                        </p>
                        <p className="block text-xs text-left font-medium text-gray-900 pointer-events-none">
                          {partItem.isDiscovered
                            ? "Discovered by unicute #" + partItem.discoveredBy
                            : "Unknown " + props.name}
                        </p>
                      </div>
                    </li>
                  </LazyLoad>
                );
              })}
            ;
          </ul>
        </div>
      </div>
    </>
  );
}
