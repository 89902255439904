import HeaderSection from "./HeaderSection";
import FeatureSection from "./FeatureSection";
import {} from "@heroicons/react/outline";
import Footer from "../../components/footer/Footer";
export default function Home() {
  // Create contract instance with some methods
  return (
    <div>
      <HeaderSection />
      <div className="mt-32 text-left">
        <FeatureSection />
      </div>
    </div>
  );
}
