import toast from "react-hot-toast";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  BanIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import {
  PrimaryButtonStyle,
} from "../button/ButtonStyle";
import { Link } from "react-router-dom";

export const PendingNotification = (
  title,
  message,
  linkText = null,
  link = null
) => {
  Notification(
    title,
    message,
    "bg-unicuteYellow-500",
    ClockIcon,
    linkText,
    link
  );
};
export const SuccessNotification = (
  title,
  message,
  linkText = null,
  link = null
) => {
  Notification(
    title,
    message,
    "bg-unicuteGreen-100",
    CheckCircleIcon,
    linkText,
    link
  );
};
export const InformationNotification = (
  title,
  messsage,
  linkText = null,
  link = null
) => {
  Notification(
    title,
    messsage,
    "bg-unicuteBlue-500",
    InformationCircleIcon,
    linkText,
    link
  );
};
export const ErrorNotification = (title, message) => {
  Notification(title, message, "bg-red-400", BanIcon);
};
export const Notification = (
  title,
  message,
  statusColor,
  Icon,
  linkText = null,
  link = null
) => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } text-left max-w-sm w-full bg-white shadow-common border-2 border-gray-900 rounded-none pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`}
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <Icon
              className={
                "h-10 w-10 p-1 shadow-common text-gray-900 border-2 border-gray-900 " +
                statusColor
              }
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <p className="mt-1 text-sm text-gray-500">{message}</p>
            {link !== null ? (
              <div className="mt-4 flex">
                <Link to={link}>
                  <button type="button" className={PrimaryButtonStyle()}>
                    {linkText}
                  </button>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="ml-4 flex-shrink-0 flex">
            <button
              className="bg-white rounded-none inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ));
};
