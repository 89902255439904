import reactSmoothImage from "react-smooth-image";
import { CdnWorldOfUnicutesImageLocation } from "../../../utils/ZilliqaUtils";
import SmoothImage from "react-smooth-image";
import LazyLoad from "react-lazyload";

export default function WorldOfUnicutesSection(props) {
  return (
    <>
      <div className="grid grid-cols-12 gap-4 mt-4 mb-8">
        <div className="col-span-12 text-left">
          <span className="text-gray-900 text-sm font-bold text-left"></span>
        </div>
        {props.worldOfUnicutes === null || props.worldOfUnicutes.length === 0 ? (
          <div className="col-span-12 text-left">
            <span className="text-gray-900 text-sm font-bold text-left">
              <>No world of unicutes found.</>
            </span>
          </div>
        ) : (
          <>
            <div className="col-span-12 shadow-retro border-4 border-gray-900">
              <ul className="grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-3 m-0">
                {props.worldOfUnicutes !== null &&
                  props.worldOfUnicutes
                    .map((worldOfUnicute, i) => {
                      return (
                        <LazyLoad
                          key={worldOfUnicute + "-lazy-" + i}
                          height={50 * (i === 1 ? 0 : i / 3)}
                        >
                          <li
                            key={worldOfUnicute + "-li-" + i}
                            className="relative"
                          >
                            <div
                              className={
                                "bg-unicuteGreen-400 bg-opacity-20 cursor-pointer block w-full aspect-w-10 aspect-h-7 rounded-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500"
                              }
                            >
                              <div className="h-full w-full overflow-hidden group-hover:opacity-75">
                                <SmoothImage
                                  style={{
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                  }}
                                  src={
                                    CdnWorldOfUnicutesImageLocation() +
                                    worldOfUnicute.toString() +
                                    ".png"
                                  }
                                  alt={"world of unicute #" + i.toString()}
                                />
                              </div>

                              <div className="absolute top-0 left-0 pl-2 pt-1">
                                <div className="text-gray-900 p-1 font-bold text-sm"></div>
                              </div>
                            </div>
                          </li>
                        </LazyLoad>
                      );
                    })}
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
}
