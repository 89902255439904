import MyUnicutesContext from "../../../components/contexts/MyUnicutesContext";
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CdnImageLocation } from "../../../utils/ZilliqaUtils.js";
import { SecondaryButtonStyle } from "../../../components/button/ButtonStyle";
import { BurnModal } from "./burn/BurnModal";

export default function UnicutesSection(props) {
  const { setShowUnicuteWithId } = useContext(MyUnicutesContext);
  const [showBurnModal, setShowBurnModal] = useState(false);
  const [burnUnicuteId, setBurnUnicuteId] = useState(null);
  function KeyExist(tokenId) {
    var obj = props.tokenBidders[tokenId.toString()];
    if (obj === null || obj === undefined) return false;
    return Object.keys(obj).length !== 0;
  }

  return (
    <>
      <BurnModal showModal={showBurnModal} setShowModal={setShowBurnModal} tokenId={burnUnicuteId} />
      <div className="grid grid-cols-12 gap-4 mt-4 mb-8">
        <div className="col-span-12 text-left">
          <span className="text-gray-900 text-sm font-bold text-left">
            Unicutes with a{" "}
            <span className="bg-unicutePurple-400 p-1">purple</span> backround
            color has one or more active bids.{" "}
          </span>
        </div>
        {props.unicutes === null ||
        props.unicutes === undefined ||
        props.unicutes.length === 0 ? (
          <div className="col-span-12">
            <span className="text-gray-900 text-sm font-medium">
              No unicutes found.
            </span>
          </div>
        ) : (
          <div className="col-span-12 shadow-retro border-4 border-gray-900">
            <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 m-0 -mr-0.5 -mb-0.5">
              {props.unicutes !== null &&
                props.unicutes.map((unicute) => {
                  return (
                    <li key={unicute.id} className="relative cursor-pointer">
                      <NavLink
                        onClick={() => setShowUnicuteWithId(unicute.id)}
                        to={"/my-unicutes/unicutes/" + unicute.id.toString()}
                        exact
                      >
                        <div
                          className={
                            (KeyExist(unicute.id)
                              ? "bg-unicutePurple-400 "
                              : "bg-unicuteGreen-400 bg-opacity-20 ") +
                            "cursor-pointer pb-8 border-gray-900 border-r-2 border-b-2 block w-full aspect-w-10 aspect-h-7 rounded-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500"
                          }
                        >
                          <img
                            src={
                              unicute.drandValue === undefined ||
                              unicute.drandValue === null
                                ? "/00.png"
                                : CdnImageLocation() +
                                  "-output/" +
                                  unicute.id +
                                  ".png"
                            }
                            alt=""
                            style={{
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              overflow: "hidden",
                            }}
                            className="overflow-hidden p-2 h-full w-full transform scale-125 pointer-events-none group-hover:opacity-75"
                          />
                          <button
                            type="button"
                            className="absolute inset-0 focus:outline-none"
                          >
                            <span className="sr-only">View details for </span>
                          </button>
                          <div className="absolute bottom-0 left-0 pl-2 pb-1">
                            <div className="text-gray-900 p-1 font-bold text-sm">
                              {unicute.drandRound === null ||
                              unicute.drandRound === undefined
                                ? "Queued for drand rounds"
                                : unicute.drandValue === null ||
                                  unicute.drandValue === undefined
                                ? "Awaiting drand values"
                                : "Generated"}
                            </div>
                          </div>
                          <div className="absolute top-0 left-0 pl-2 pt-1">
                            <div className="text-gray-900 p-1 font-bold text-sm">
                              #{unicute.id}
                            </div>
                          </div>
                        </div>
                      </NavLink>
                          <div className="absolute bottom-0 right-0 pr-2 pb-1">
                            <div className="text-gray-900 p-1 font-bold text-sm">
                              {unicute.drandValue !== undefined &&
                                unicute.drandValue !== null && (
                                  <button
                                    onClick={() => {
                                      setBurnUnicuteId(unicute.id);
                                      setShowBurnModal(true);
                                    }}
                                    className={SecondaryButtonStyle("")}
                                  >
                                    Burn
                                  </button>
                                )}
                            </div>
                          </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
