/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

const faqs = [
  {
    question: "What is unicutes?",
    answer: `Unicutes is a deflationary and verifiable random generated art
      collection using a distributed randomness beacon on the Zilliqa
      blockchain.`,
  },
  {
    question: "How do I get a unicute?",
    answer: `If you want to get your hands on a unicute, claim an egg and hatch it.`,
  },
  {
    question: "How can unicutes be deflationary?",
    answer:
      "Unicutes will have one or more art sub-collections. The entry pass to a new art collection will be by burning a unicute. Ghost unicutes was the first art collection where one had to burn a unicute to get a ghost unicute.",
  },
  {
    question: "How many unicutes will exist?",
    answer:
      "Up to 9'999 unicutes, not more than this. It's hardcoded in the smart contract.",
  },
  {
    question: "What is a full set?",
    answer:
      "A full set unicute is where all the parts come from the same original designed unicute. We had 76 unicutes originally designed so there are in total 76 full sets unicutes.",
  },
  {
    question: "What is the probability of getting a full set?",
    answer: "1 out of 255.",
  },
  {
    question: "How many full sets exist?",
    answer: "76 in total. And each full set have a variety of colors.",
  },
  {
    question: "Where does the name unicutes come from?",
    answer: `The name unicutes comes from 2 words, "unicorn" and "cute".`,
  },
  {
    question: "How are unicutes generated?",
    answer: `All unicutes are generated on the fly, after an egg has hatched, using a verifiable, unpredictable, and unbiased RNG (random number generator).`,
  },
  {
    question: "Can I transfer unicute egg?",
    answer: "No. Unicute eggs are non-transferable.",
  },
  {
    question: "What is ghost unicutes?",
    answer:
      "Ghost unicutes is a very limited sub-collection to the original unicutes collection. There exist only 999 ghost unicutes so be quick!",
  },
  {
    question: "How do I get a ghost unicute?",
    answer: "A unicute must be sacrificed by burning it. To burn a unicute click on the menu item 'My unicutes' then go to the 'Unicutes' tab and press the burn button for the unicute you wish to burn.",
  },
  // More questions...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Faq() {
  return (
    <>
      <div className="">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200 bg-gray-50 shadow-retro border-2 border-gray-900 p-8">
            <h2 className="text-center font-medium text-gray-900 text-xl">
              Frequently asked questions
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-900">
                          <span className="font-medium text-md text-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-5 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-5 w-5 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-sm font-medium text-left text-gray-900">
                          {faq.answer}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
